import { Trade, TradeType, CurrencyAmount, Token, WETH, ChainId, ETHER } from '@safemoon/sdk'
import React, { useContext, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { TYPE } from '../../theme'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity
} from '../../utils/prices'
import { ButtonError, ButtonPrimary } from '../Button'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { Dots, StyledBalanceMaxMiniSmall, SwapCallbackError } from './styleds'
import { useActiveWeb3React } from '../../hooks'
import getTokenSymbol, { getNativeSymbol } from '../../utils/getTokenSymbol'
import { displayTokenPrice, formatStringToNumber, formatStringToPrice, isStableCoin } from '../../utils'
import ExchangePrice from '../../assets/icons/exchange-price.svg'
import SVG from 'react-inlinesvg'
import { useGasPrice } from '../../state/user/hooks'
import { ApprovalState } from '../../hooks/useApproveCallback'
// import FormattedPriceImpact0xApi from './FormattedPriceImpact0xApi'

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  ethFee,
  swapErrorMessage,
  disabledConfirm,
  networkFee,
  nativePriceUsd,
  priceUsd,
  priceInfo,
  parsedAmounts,
  useGasless,
  signApproval,
  signApprovalSignature,
  signTrade,
  signTradeSignature,
  showSellTokenApproveFlow,
  sellTokenApprovalSubmitted,
  sellTokenApproval,
  sellTokenApprovalCallback,
  priceImpact0xApi
}: {
  trade?: Trade
  allowedSlippage: number
  ethFee?: string
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
  networkFee?: any
  nativePriceUsd: number
  priceUsd?: any
  priceInfo?: any
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  useGasless?: boolean
  signApproval?: () => void
  signApprovalSignature?: string
  signTrade?: () => void
  signTradeSignature?: string
  showSellTokenApproveFlow?: boolean
  sellTokenApprovalSubmitted?: boolean
  sellTokenApproval?: ApprovalState
  sellTokenApprovalCallback?: () => Promise<void>
  priceImpact0xApi?: number
}) {
  const { chainId } = useActiveWeb3React()
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const theme = useContext(ThemeContext)
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    allowedSlippage,
    trade
  ])

  const gasPrice = +useGasPrice() / 10 ** 9

  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)

  const isTradeRouter = true

  const isExactIn = trade ? trade?.tradeType === TradeType.EXACT_INPUT : true
  const minCurrencyOut: any =
    priceInfo &&
    parsedAmounts?.[Field.OUTPUT] &&
    new CurrencyAmount(parsedAmounts[Field.OUTPUT]?.currency, priceInfo?.minBuyAmount)

  const currencyIn: any =
    priceInfo && parsedAmounts[Field.INPUT] && parsedAmounts[Field.INPUT]?.currency?.symbol !== 'SFM'
      ? new CurrencyAmount(parsedAmounts[Field.INPUT]?.currency, priceInfo?.sellAmount)
      : priceInfo &&
        parsedAmounts[Field.OUTPUT] &&
        new CurrencyAmount((parsedAmounts[Field.OUTPUT] as any)?.currency, priceInfo?.buyAmount)

  const swapFeeUsd = useMemo(() => {
    if (!trade && currencyIn) {
      const symbol = currencyIn?.currency?.symbol
      let address = currencyIn?.currency?.address
      if (symbol === 'ETH' && !address) {
        address = WETH[chainId as ChainId]?.address
      } else if (symbol === 'ETH' && address && chainId === 56) {
        address = WETH[1]?.address
      }

      return currencyIn && priceUsd[address?.toLowerCase()]
        ? +currencyIn.toFixed() * 0.0025 * priceUsd[address?.toLowerCase()]
        : null
    }
    return 0
  }, [priceUsd, chainId, currencyIn?.toFixed()])

  const minimumReceivedUsd = useMemo(() => {
    if (!trade && minCurrencyOut) {
      const symbol = minCurrencyOut?.currency?.symbol
      let address = minCurrencyOut?.currency?.address
      if (symbol === 'ETH' && !address) {
        address = WETH[chainId as ChainId]?.address
      } else if (symbol === 'ETH' && address && chainId === 56) {
        address = WETH[1]?.address
      }

      return minCurrencyOut && priceUsd[address?.toLowerCase()]
        ? minCurrencyOut.toFixed() * priceUsd[address?.toLowerCase()]
        : null
    }
    const symbol = isExactIn ? trade?.outputAmount.currency?.symbol : trade?.inputAmount.currency?.symbol
    let address = isExactIn
      ? (trade?.outputAmount.currency as Token)?.address
      : (trade?.inputAmount.currency as Token)?.address
    if (symbol === 'ETH' && !address) {
      address = WETH[chainId as ChainId]?.address
    } else if (symbol === 'ETH' && address && chainId === 56) {
      address = WETH[1]?.address
    }

    return slippageAdjustedAmounts && slippageAdjustedAmounts[isExactIn ? Field.OUTPUT : Field.INPUT]
      ? +(slippageAdjustedAmounts[isExactIn ? Field.OUTPUT : Field.INPUT]?.toFixed() || 0) *
          priceUsd[address?.toLowerCase()]
      : 0
  }, [isExactIn, slippageAdjustedAmounts, priceUsd, chainId])

  return (
    <>
      <AutoColumn gap="0px">
        <div className="flex items-center justify-center mb-[12px]">
          <Text fontWeight={500} fontSize={14} color={theme.text2}>
            {formatExecutionPrice(trade, parsedAmounts, showInverted, chainId)}
          </Text>
          <StyledBalanceMaxMiniSmall onClick={() => setShowInverted(!showInverted)}>
            <SVG color={theme.text1} src={ExchangePrice} width={18} height={18} />
          </StyledBalanceMaxMiniSmall>
        </div>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel} style={{ whiteSpace: 'nowrap' }}>
              {!trade || trade?.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
            </TYPE.black>
            <QuestionHelper
              size={'xs'}
              text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
            />
          </RowFixed>
          {trade ? (
            <TYPE.black fontSize={14} color={theme.secondary2} flex={1} textAlign="right">
              {trade?.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}{' '}
              {trade?.tradeType === TradeType.EXACT_INPUT
                ? getTokenSymbol(trade?.outputAmount.currency, chainId)
                : getTokenSymbol(trade?.inputAmount.currency, chainId)}{' '}
              {!isStableCoin(isExactIn ? trade?.outputAmount.currency?.symbol : trade?.inputAmount.currency?.symbol) &&
                `($${formatStringToPrice(minimumReceivedUsd)})`}
            </TYPE.black>
          ) : (
            <TYPE.black fontSize={14} color={theme.secondary2} flex={1} textAlign="right">
              {minCurrencyOut.toSignificant(4) ?? '-'}{' '}
              {getTokenSymbol(parsedAmounts?.[Field.OUTPUT]?.currency, chainId)}{' '}
              {!isStableCoin(parsedAmounts?.[Field.OUTPUT]?.currency.symbol) &&
                `($${formatStringToPrice(minimumReceivedUsd)})`}
            </TYPE.black>
          )}
        </RowBetween>
        {!trade && priceInfo && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel} style={{ whiteSpace: 'nowrap' }}>
                Swap fee
              </TYPE.black>
              <QuestionHelper text={'0.25% swap fee in sell token'} size={'xs'} />
            </RowFixed>
            <TYPE.black color={theme.white} fontSize={14} fontWeight={400} flex={1} textAlign="right">
              {formatStringToNumber(+currencyIn?.toExact() * 0.0025, 8)} {getTokenSymbol(currencyIn?.currency, chainId)}{' '}
              {!isStableCoin(currencyIn?.currency?.symbol) && `($${displayTokenPrice(swapFeeUsd)})`}
            </TYPE.black>
          </RowBetween>
        )}
        {trade && (
          <RowBetween>
            <RowFixed>
              <TYPE.black color={theme.textLabel} fontSize={14} fontWeight={400}>
                Price Impact
              </TYPE.black>
              <QuestionHelper
                size={'xs'}
                text="The difference between the market price and your price due to trade size."
              />
            </RowFixed>
            <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
          </RowBetween>
        )}

        {/* {priceInfo && !trade && +(priceImpact0xApi || 0) && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
                Price Impact
              </TYPE.black>
              <QuestionHelper
                text="The difference between the market price and your price due to trade size."
                size={'xs'}
              />
            </RowFixed>
            <FormattedPriceImpact0xApi priceImpact={priceImpact0xApi} />
          </RowBetween>
        )} */}

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
              Network Fee ({gasPrice} GWEI)
            </TYPE.black>
            <QuestionHelper
              size={'xs'}
              text="This is the gas fee charged by the blockchain for this transaction. If you wish to change the default transaction speed, you may do so in the settings."
            />
          </RowFixed>
          <div className="text-right">
            {useGasless ? (
              <TYPE.black fontSize={14}>0</TYPE.black>
            ) : (
              <>
                {trade ? (
                  <TYPE.black fontSize={14}>
                    {networkFee?.networkFee
                      ? formatStringToNumber(networkFee?.networkFee, 8) + ' ' + getNativeSymbol(chainId)
                      : '-'}{' '}
                    (${formatStringToNumber(nativePriceUsd * networkFee?.networkFee)})
                  </TYPE.black>
                ) : (
                  <TYPE.black fontSize={14}>
                    {priceInfo?.totalNetworkFee
                      ? formatStringToNumber(+priceInfo?.totalNetworkFee / 10 ** 18, 8) + ' ' + getNativeSymbol(chainId)
                      : '-'}{' '}
                    (${formatStringToNumber(nativePriceUsd * (+priceInfo?.totalNetworkFee / 10 ** 18))})
                  </TYPE.black>
                )}
              </>
            )}
          </div>
        </RowBetween>
        {!isTradeRouter && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
                SafeMoon Swap Fee
              </TYPE.black>
              <QuestionHelper
                size={'xs'}
                text="A portion of each trade (0.25%) goes towards supporting the ecosystem."
              />
            </RowFixed>
            <TYPE.black fontSize={14}>
              {realizedLPFee
                ? realizedLPFee?.toSignificant(6) + ' ' + getTokenSymbol(trade?.inputAmount.currency, chainId)
                : '-'}
            </TYPE.black>
          </RowBetween>
        )}

        {ethFee && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel} style={{ whiteSpace: 'nowrap' }}>
                Swap Fee In {getNativeSymbol(chainId)}
              </TYPE.black>
              <QuestionHelper
                size={'xs'}
                text={`There is a 0.25% fee in ${getNativeSymbol(
                  chainId
                )} for each trade. Some tokens may take an additional portion of their fees in ${getNativeSymbol(
                  chainId
                )}. This is displayed here in the total fee amount.`}
              />
            </RowFixed>
            <TYPE.black fontSize={14} style={{ textAlign: 'right' }} flex={1}>
              {formatStringToNumber(CurrencyAmount.ether(ethFee).toExact(), 8)} {getNativeSymbol(chainId)} ($
              {formatStringToPrice(nativePriceUsd * +CurrencyAmount.ether(ethFee).toExact())})
            </TYPE.black>
          </RowBetween>
        )}

        {!useGasless && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
                Max {getNativeSymbol(chainId)} Total
              </TYPE.black>
              <QuestionHelper
                size={'xs'}
                text="This is the maximum amount you will spend on this transaction in the chains Native Coin."
              />
            </RowFixed>
            {trade ? (
              <TYPE.black fontSize={14} color={theme.yellow1} textAlign={'right'}>
                {networkFee
                  ? formatStringToNumber(networkFee?.maxNetworkFee + networkFee?.value, 8) +
                    ' ' +
                    getNativeSymbol(chainId)
                  : '-'}{' '}
                (${formatStringToNumber(nativePriceUsd * (networkFee?.maxNetworkFee + networkFee?.value))})
              </TYPE.black>
            ) : (
              <TYPE.black fontSize={14} color={theme.yellow1} textAlign={'right'}>
                {priceInfo?.totalNetworkFee
                  ? formatStringToNumber(
                      +priceInfo?.totalNetworkFee / 10 ** 18 +
                        (currencyIn.currency === ETHER ? +currencyIn?.toExact() * 0.0025 : 0),
                      8
                    ) +
                    ' ' +
                    getNativeSymbol(chainId)
                  : '-'}{' '}
                ($
                {formatStringToNumber(
                  nativePriceUsd *
                    (+priceInfo?.totalNetworkFee / 10 ** 18 +
                      (currencyIn.currency === ETHER ? +currencyIn?.toExact() * 0.0025 : 0))
                )}
                )
              </TYPE.black>
            )}
          </RowBetween>
        )}
      </AutoColumn>

      <AutoRow>
        {useGasless ? (
          <div className="flex item-center w-full flex-wrap">
            {priceInfo?.issues?.allowance && (
              <>
                {priceInfo?.approval?.eip712 ? (
                  <div className="flex-1 m-[4px] min-w-[150px]">
                    <ButtonError
                      onClick={signApproval}
                      disabled={!!signApprovalSignature}
                      error={trade && severity > 2}
                      style={{ height: '50px' }}
                      id="confirm-swap-or-send"
                    >
                      <Text fontSize={'16px'} fontWeight={'bold'}>
                        {signApprovalSignature ? 'Signed Approval' : 'Sign Approval'}
                      </Text>
                    </ButtonError>
                  </div>
                ) : (
                  <div className="flex-1 m-[4px] min-w-[150px]">
                    <ButtonError
                      onClick={sellTokenApprovalCallback}
                      disabled={sellTokenApproval !== ApprovalState.NOT_APPROVED || sellTokenApprovalSubmitted}
                      width="100%"
                    >
                      <Text fontSize={'16px'} fontWeight={'bold'}>
                        {sellTokenApproval === ApprovalState.PENDING ? (
                          <Dots>Approving</Dots>
                        ) : sellTokenApprovalSubmitted && sellTokenApproval === ApprovalState.APPROVED ? (
                          'Approved'
                        ) : (
                          'Approve ' + getTokenSymbol(parsedAmounts[Field.INPUT]?.currency, chainId)
                        )}
                      </Text>
                    </ButtonError>
                  </div>
                )}
              </>
            )}

            {priceInfo?.trade?.eip712 && (
              <div className="flex-1 m-[4px] min-w-[150px]">
                <ButtonError
                  onClick={signTrade}
                  disabled={!!signTradeSignature}
                  error={trade && severity > 2}
                  style={{ height: '50px' }}
                  id="confirm-swap-or-send"
                >
                  <Text fontSize={'16px'} fontWeight={'bold'}>
                    {signTradeSignature ? 'Signed Trade' : 'Sign Trade'}
                  </Text>
                </ButtonError>
              </div>
            )}

            <div className="flex-1 m-[4px] min-w-[150px]">
              <ButtonError
                onClick={onConfirm}
                disabled={
                  disabledConfirm ||
                  !signTradeSignature ||
                  (priceInfo?.issues?.allowance &&
                    ((priceInfo?.approval?.eip712 && !signApprovalSignature) ||
                      (!priceInfo?.approval?.eip712 &&
                        !(sellTokenApprovalSubmitted && sellTokenApproval === ApprovalState.APPROVED))))
                }
                error={trade && severity > 2}
                style={{ height: '50px' }}
                id="confirm-swap-or-send"
              >
                <Text fontSize={'16px'} fontWeight={'bold'}>
                  {trade && severity > 2 ? 'Swap Anyway' : 'Confirm Swap'}
                </Text>
              </ButtonError>
            </div>
          </div>
        ) : (
          <ButtonError
            onClick={onConfirm}
            disabled={disabledConfirm}
            error={trade && severity > 2}
            style={{ margin: '10px 0 0 0' }}
            id="confirm-swap-or-send"
          >
            <Text fontSize={'14px'} fontWeight={'bold'}>
              {trade && severity > 2 ? 'Swap Anyway' : 'Confirm Swap'}
            </Text>
          </ButtonError>
        )}

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
