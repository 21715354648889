import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Tooltip from '../Tooltip'
import SVG from 'react-inlinesvg'
import { useTheme } from '../../hooks/useTheme'
import classNames from 'classnames'
import { Placement } from '@popperjs/core'

const QuestionWrapper = styled.div<{ size?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => (size === 'lg' ? '40px' : size === 'md' ? '32px' : '24px')};
  height: ${({ size }) => (size === 'lg' ? '40px' : size === 'md' ? '32px' : '24px')};
  border: none;
  outline: none;
  cursor: default;
  opacity: 0.5;

  :hover,
  :focus {
    opacity: 1;
  }
`

export default function QuestionHelper({
  text,
  disabled,
  size = 'lg',
  className = '',
  label = '',
  icon
}: {
  text: string | any
  disabled?: boolean
  size?: string
  className?: string
  label?: string
  icon?: string
}) {
  const [show, setShow] = useState<boolean>(false)
  const theme = useTheme()

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span className={classNames('ml-[4px]', className)}>
      <Tooltip text={text} show={show && !disabled}>
        <QuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close} size={size}>
          {label}
          <SVG
            src={icon || '/images/info.svg'}
            width={size === 'lg' ? 24 : size === 'md' ? 20 : size === 'xs' ? 13 : 16}
            height={size === 'lg' ? 24 : size === 'md' ? 20 : size === 'xs' ? 13 : 16}
            color={theme.text1}
          />
        </QuestionWrapper>
      </Tooltip>
    </span>
  )
}

interface HelperProps {
  text: string | any
  disabled?: boolean
  className?: string
  children?: any
  placement?: Placement
}

export function Helper({ text, disabled, className = '', children, placement = 'right' }: HelperProps) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <div className={classNames('ml-[4px]', className)}>
      <Tooltip text={text} show={show && !disabled} placement={placement}>
        <div onClick={open} onMouseEnter={open} onMouseLeave={close}>
          {children}
        </div>
      </Tooltip>
    </div>
  )
}
