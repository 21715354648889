import { AbstractConnector } from '@web3-react/abstract-connector'
import { ChainId, JSBI, Percent, Token, WETH } from '@safemoon/sdk'

import { injected, walletconnect, walletconnectMultiTestnet } from '../connectors'
import { BigNumber } from '@ethersproject/bignumber'
import { BigNumberish } from 'ethers'
import mitt from 'mitt'

import BNBLogo from '../assets/images/bnb.svg'
import EthereumLogo from '../assets/images/eth_icon.svg'
import MATICLogo from '../assets/images/matic.png'
import safemoonLogo from '../assets/images/SafeMoonWallet.svg'
import vgxLogo from '../assets/images/vgx.png'
import ArbitrumLogo from '../assets/images/arbitrum.png'
import AvalancheLogo from '../assets/images/avalanche.png'
import BaseLogo from '../assets/images/base.png'

export interface BirdgeChain {
  images: string[]
  chain: string
  chainId: number
  tokenAddress: string
  rpcUrl: string
  chainName: string
  color: string
  decimals: number
  shortChain: string
  currency: string
  hexChain: string
  network: number
  id: number
  symbol: string
}

export enum PopupTypes {
  BLACKLIST_WALLET = 'BLACKLIST_WALLET',
  BLACKLIST_TOKEN = 'BLACKLIST_TOKEN'
}

export const popupEmitter = mitt()

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

type ChainAddress = {
  [chainId in ChainId]: string
}

type Address = {
  [chainId in ChainId]: string
}

type Tokens = {
  [chainId in ChainId]: Token
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const FACTORY_ADDRESS: ChainAddress = {
  [ChainId.MAINNET]: '0x7F09d4bE6bbF4b0fF0C97ca5c486a166198aEAeE',
  [ChainId.ROPSTEN]: '0xDfD8bbA37423950bD8050C65E698610C57E55cea',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.BSC_MAINNET]: '0x505751023083BfC7D2AFB0588717dCC6182e54B2',
  [ChainId.BSC_TESTNET]: '0x4f5c641ee8f06a64d5ba792aedab502a73878c22',
  [ChainId.SEPOLIA_TESTNET]: '0xb33Db820e58E38b3f8E0C812e957AAE6Df268942',
  [ChainId.MUMBAI_TESTNET]: '0xB9AA9e8d78e905D3e6e331aC67823d9aAc9b9cE7',
  [ChainId.POLYGON]: '0x8a00a21f2f654c4545a0272888377929dc3df4df',
  [ChainId.ARB_TESTNET]: '0x431b62bD94EC4480B6A7E44EE783A7F8c791ca2B',
  [ChainId.AVALANCHE_FUJI]: '0x5c54F1ccAA4Bbe812D11C911E4ad42A653374C9B',
  [ChainId.BASE_SEPOLIA]: '0x431b62bD94EC4480B6A7E44EE783A7F8c791ca2B',
  [ChainId.ARB_MAINNET]: '',
  [ChainId.AVALANCHE_C]: '',
  [ChainId.BASE_MAINNET]: '',
  [ChainId.LINEA]: '',
  [ChainId.FANTOM]: '',
  [ChainId.OPTIMISM]: '',
  [ChainId.BLAST]: ''
}

export const ROUTER_ADDRESS: ChainAddress = {
  [ChainId.MAINNET]: '0x0a654cb371fbc3c49ccb176984d64e86fc931eea',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.BSC_MAINNET]: '0x6AC68913d8FcCD52d196B09e6bC0205735A4be5f',
  [ChainId.ROPSTEN]: '0x713702D3fb45BC9765d3A00e0B37c33f9CE9Ec91',
  [ChainId.BSC_TESTNET]: '0x922a689ac9787f7e8edee6e819d123cad4f29faa',
  [ChainId.SEPOLIA_TESTNET]: '0x7F2E935C802d25354C637Cda804AbE01115bB718',
  [ChainId.MUMBAI_TESTNET]: '0xeb5c7114273c2b09016B28055314a0524b706D04',
  [ChainId.POLYGON]: '0x9a7ba8eba135b789efddb15e0ce42572efa3b792',
  [ChainId.ARB_TESTNET]: '0x4336c285d2e52A2304fEEA845F23def84eA8A291',
  [ChainId.AVALANCHE_FUJI]: '0x0a88b28Fb56dE61239a6D6184F9A66838Abfc24d',
  [ChainId.BASE_SEPOLIA]: '0x4336c285d2e52A2304fEEA845F23def84eA8A291',
  [ChainId.ARB_MAINNET]: '',
  [ChainId.AVALANCHE_C]: '',
  [ChainId.BASE_MAINNET]: '',
  [ChainId.LINEA]: '',
  [ChainId.FANTOM]: '',
  [ChainId.OPTIMISM]: '',
  [ChainId.BLAST]: ''
}

export const TRADE_SWAP_ROUTER_ADDRESS: ChainAddress = {
  [ChainId.MAINNET]: '0x3816016D0855A2b35b90DddcAe4A2e9Ad690947d',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.BSC_MAINNET]: '0x524BC73fCb4fB70E2E84dC08EFE255252A3b026E',
  [ChainId.ROPSTEN]: '',
  [ChainId.BSC_TESTNET]: '0xd4434ffdb434999ebbbef0d98a2364fadb75241c',
  [ChainId.SEPOLIA_TESTNET]: '0x15a8381D8A6E209Ba0CA3F27FE848119B3A6feD0',
  [ChainId.MUMBAI_TESTNET]: '0xFbC9A38615141611cF2100cA51B1e8DC3bf0Fad9',
  [ChainId.POLYGON]: '0x4911f3ec32d4e72bbab1f387944f4e350adcee96',
  [ChainId.ARB_TESTNET]: '0x0c669A32677D8241235C6C87390851Ba5A7e4544',
  [ChainId.AVALANCHE_FUJI]: '0xb9Bf4c99fd05b2DeC1780b749dccfcF424A9e867',
  [ChainId.BASE_SEPOLIA]: '0x0c669A32677D8241235C6C87390851Ba5A7e4544',
  [ChainId.ARB_MAINNET]: '',
  [ChainId.AVALANCHE_C]: '',
  [ChainId.BASE_MAINNET]: '',
  [ChainId.LINEA]: '',
  [ChainId.FANTOM]: '',
  [ChainId.OPTIMISM]: '',
  [ChainId.BLAST]: ''
}

export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C')
export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')
export const COMP = new Token(ChainId.MAINNET, '0xc00e94Cb662C3520282E6f5717214004A7f26888', 18, 'COMP', 'Compound')
export const MKR = new Token(ChainId.MAINNET, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker')
export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')

export const BAI = new Token(
  ChainId.BSC_MAINNET,
  '0xaA8012a0Ea627767545a8E435C2A2BD51f60173D',
  18,
  'BAI',
  'BAI Stablecoin'
)
export const B_DAI = new Token(
  ChainId.BSC_MAINNET,
  '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  18,
  'DAI',
  'Dai Token'
)
export const BUSD = new Token(
  ChainId.BSC_MAINNET,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'BUSD Token'
)
export const B_USDT = new Token(
  ChainId.BSC_MAINNET,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD'
)

export const T_DAI = new Token(
  ChainId.BSC_TESTNET,
  '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867',
  18,
  'DAI',
  'Dai Token'
)
export const T_BUSD = new Token(
  ChainId.BSC_TESTNET,
  '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
  6,
  'BUSD',
  'BUSD Token'
)
export const T_USDT = new Token(
  ChainId.BSC_TESTNET,
  '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
  6,
  'USDT',
  'Tether USD'
)

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.BSC_MAINNET]: [WETH[ChainId.BSC_MAINNET]],
  [ChainId.BSC_TESTNET]: [WETH[ChainId.BSC_TESTNET]],
  [ChainId.SEPOLIA_TESTNET]: [WETH[ChainId.SEPOLIA_TESTNET]],
  [ChainId.MUMBAI_TESTNET]: [WETH[ChainId.MUMBAI_TESTNET]],
  [ChainId.POLYGON]: [WETH[ChainId.POLYGON]],
  [ChainId.ARB_TESTNET]: [WETH[ChainId.ARB_TESTNET]],
  [ChainId.AVALANCHE_FUJI]: [WETH[ChainId.AVALANCHE_FUJI]],
  [ChainId.BASE_SEPOLIA]: [WETH[ChainId.BASE_SEPOLIA]],
  [ChainId.ARB_MAINNET]: [WETH[ChainId.ARB_MAINNET]],
  [ChainId.AVALANCHE_C]: [WETH[ChainId.AVALANCHE_C]],
  [ChainId.BASE_MAINNET]: [WETH[ChainId.BASE_MAINNET]],
  [ChainId.LINEA]: [WETH[ChainId.LINEA]],
  [ChainId.FANTOM]: [WETH[ChainId.FANTOM]],
  [ChainId.OPTIMISM]: [WETH[ChainId.OPTIMISM]],
  [ChainId.BLAST]: [WETH[ChainId.BLAST]]
}

export const SFM_V1: Address = {
  [ChainId.MAINNET]: ZERO_ADDRESS,
  [ChainId.ROPSTEN]: ZERO_ADDRESS,
  [ChainId.RINKEBY]: ZERO_ADDRESS,
  [ChainId.GÖRLI]: ZERO_ADDRESS,
  [ChainId.KOVAN]: ZERO_ADDRESS,
  [ChainId.BSC_MAINNET]: process.env.REACT_APP_SAFEMOON_TOKEN || ZERO_ADDRESS,
  [ChainId.BSC_TESTNET]: process.env.REACT_APP_SAFEMOON_TESTNET_TOKEN || ZERO_ADDRESS,
  [ChainId.SEPOLIA_TESTNET]: ZERO_ADDRESS,
  [ChainId.MUMBAI_TESTNET]: ZERO_ADDRESS,
  [ChainId.POLYGON]: ZERO_ADDRESS,
  [ChainId.ARB_TESTNET]: ZERO_ADDRESS,
  [ChainId.AVALANCHE_FUJI]: ZERO_ADDRESS,
  [ChainId.BASE_SEPOLIA]: ZERO_ADDRESS,
  [ChainId.ARB_MAINNET]: ZERO_ADDRESS,
  [ChainId.AVALANCHE_C]: ZERO_ADDRESS,
  [ChainId.BASE_MAINNET]: ZERO_ADDRESS,
  [ChainId.LINEA]: ZERO_ADDRESS,
  [ChainId.FANTOM]: ZERO_ADDRESS,
  [ChainId.OPTIMISM]: ZERO_ADDRESS,
  [ChainId.BLAST]: ZERO_ADDRESS
}

export const SFM_V2: Address = {
  [ChainId.MAINNET]: process.env.REACT_APP_SAFEMOONV2_ETHERIUM_TOKEN || ZERO_ADDRESS,
  [ChainId.ROPSTEN]: ZERO_ADDRESS,
  [ChainId.RINKEBY]: ZERO_ADDRESS,
  [ChainId.GÖRLI]: ZERO_ADDRESS,
  [ChainId.KOVAN]: ZERO_ADDRESS,
  [ChainId.BSC_MAINNET]: process.env.REACT_APP_SAFEMOONV2_TOKEN || ZERO_ADDRESS,
  [ChainId.BSC_TESTNET]: process.env.REACT_APP_SAFEMOONV2_BSC_TESTNET_TOKEN || ZERO_ADDRESS,
  [ChainId.SEPOLIA_TESTNET]: process.env.REACT_APP_SAFEMOONV2_SEPOLIA_TESTNET_TOKEN || ZERO_ADDRESS,
  [ChainId.MUMBAI_TESTNET]: process.env.REACT_APP_SAFEMOONV2_MUMBAI_TESTNET_TOKEN || ZERO_ADDRESS,
  [ChainId.POLYGON]: process.env.REACT_APP_SAFEMOONV2_POLYGON_TOKEN || ZERO_ADDRESS,
  [ChainId.ARB_TESTNET]: process.env.REACT_APP_SAFEMOONV2_ARB_TESTNET_TOKEN || ZERO_ADDRESS,
  [ChainId.AVALANCHE_FUJI]: process.env.REACT_APP_SAFEMOONV2_AVALANCHE_FUJI_TOKEN || ZERO_ADDRESS,
  [ChainId.BASE_SEPOLIA]: process.env.REACT_APP_SAFEMOONV2_BASE_SEPOLIA_TOKEN || ZERO_ADDRESS,
  [ChainId.ARB_MAINNET]: ZERO_ADDRESS,
  [ChainId.AVALANCHE_C]: ZERO_ADDRESS,
  [ChainId.BASE_MAINNET]: ZERO_ADDRESS,
  [ChainId.LINEA]: ZERO_ADDRESS,
  [ChainId.FANTOM]: ZERO_ADDRESS,
  [ChainId.OPTIMISM]: ZERO_ADDRESS,
  [ChainId.BLAST]: ZERO_ADDRESS
}

export const MIGRATION: Address = {
  [ChainId.MAINNET]: ZERO_ADDRESS,
  [ChainId.ROPSTEN]: ZERO_ADDRESS,
  [ChainId.RINKEBY]: ZERO_ADDRESS,
  [ChainId.GÖRLI]: ZERO_ADDRESS,
  [ChainId.KOVAN]: ZERO_ADDRESS,
  [ChainId.BSC_MAINNET]: process.env.REACT_APP_MIGRATION || ZERO_ADDRESS,
  [ChainId.BSC_TESTNET]: process.env.REACT_APP_MIGRATION_TESTNET || ZERO_ADDRESS,
  [ChainId.SEPOLIA_TESTNET]: ZERO_ADDRESS,
  [ChainId.MUMBAI_TESTNET]: ZERO_ADDRESS,
  [ChainId.POLYGON]: ZERO_ADDRESS,
  [ChainId.ARB_TESTNET]: ZERO_ADDRESS,
  [ChainId.AVALANCHE_FUJI]: ZERO_ADDRESS,
  [ChainId.BASE_SEPOLIA]: ZERO_ADDRESS,
  [ChainId.ARB_MAINNET]: ZERO_ADDRESS,
  [ChainId.AVALANCHE_C]: ZERO_ADDRESS,
  [ChainId.BASE_MAINNET]: ZERO_ADDRESS,
  [ChainId.LINEA]: ZERO_ADDRESS,
  [ChainId.FANTOM]: ZERO_ADDRESS,
  [ChainId.OPTIMISM]: ZERO_ADDRESS,
  [ChainId.BLAST]: ZERO_ADDRESS
}

export const SfmV1: Tokens = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, SFM_V1[ChainId.MAINNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, SFM_V1[ChainId.ROPSTEN], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, SFM_V1[ChainId.RINKEBY], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, SFM_V1[ChainId.GÖRLI], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, SFM_V1[ChainId.KOVAN], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.SEPOLIA_TESTNET]: new Token(
    ChainId.SEPOLIA_TESTNET,
    SFM_V1[ChainId.SEPOLIA_TESTNET],
    9,
    'SAFEMOON',
    'SafeMoon'
  ),
  [ChainId.MUMBAI_TESTNET]: new Token(
    ChainId.MUMBAI_TESTNET,
    SFM_V1[ChainId.MUMBAI_TESTNET],
    9,
    'SAFEMOON',
    'SafeMoon'
  ),
  [ChainId.POLYGON]: new Token(ChainId.POLYGON, SFM_V1[ChainId.POLYGON], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.ARB_TESTNET]: new Token(ChainId.ARB_TESTNET, SFM_V1[ChainId.ARB_TESTNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.BSC_MAINNET]: new Token(ChainId.BSC_MAINNET, SFM_V1[ChainId.BSC_MAINNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.BSC_TESTNET]: new Token(ChainId.BSC_TESTNET, SFM_V1[ChainId.BSC_TESTNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.AVALANCHE_FUJI]: new Token(
    ChainId.AVALANCHE_FUJI,
    SFM_V1[ChainId.AVALANCHE_FUJI],
    9,
    'SAFEMOON',
    'SafeMoon'
  ),
  [ChainId.BASE_SEPOLIA]: new Token(ChainId.BASE_SEPOLIA, SFM_V1[ChainId.BASE_SEPOLIA], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.ARB_MAINNET]: new Token(ChainId.ARB_MAINNET, SFM_V1[ChainId.ARB_MAINNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.AVALANCHE_C]: new Token(ChainId.AVALANCHE_C, SFM_V1[ChainId.AVALANCHE_C], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.BASE_MAINNET]: new Token(ChainId.BASE_MAINNET, SFM_V1[ChainId.BASE_MAINNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.LINEA]: new Token(ChainId.LINEA, SFM_V1[ChainId.LINEA], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.FANTOM]: new Token(ChainId.FANTOM, SFM_V1[ChainId.FANTOM], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.OPTIMISM]: new Token(ChainId.OPTIMISM, SFM_V1[ChainId.OPTIMISM], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.BLAST]: new Token(ChainId.BLAST, SFM_V1[ChainId.BLAST], 9, 'SAFEMOON', 'SafeMoon')
}

export const SfmV2: Tokens = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, SFM_V1[ChainId.MAINNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, SFM_V1[ChainId.ROPSTEN], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, SFM_V1[ChainId.RINKEBY], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, SFM_V1[ChainId.GÖRLI], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, SFM_V1[ChainId.KOVAN], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.SEPOLIA_TESTNET]: new Token(
    ChainId.SEPOLIA_TESTNET,
    SFM_V2[ChainId.SEPOLIA_TESTNET],
    9,
    'SAFEMOON',
    'SafeMoon'
  ),
  [ChainId.MUMBAI_TESTNET]: new Token(
    ChainId.MUMBAI_TESTNET,
    SFM_V2[ChainId.MUMBAI_TESTNET],
    9,
    'SAFEMOON',
    'SafeMoon'
  ),
  [ChainId.POLYGON]: new Token(ChainId.POLYGON, SFM_V2[ChainId.POLYGON], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.ARB_TESTNET]: new Token(ChainId.ARB_TESTNET, SFM_V2[ChainId.ARB_TESTNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.BSC_MAINNET]: new Token(ChainId.BSC_MAINNET, SFM_V2[ChainId.BSC_MAINNET], 9, 'SFM', 'SafeMoon V2'),
  [ChainId.BSC_TESTNET]: new Token(ChainId.BSC_TESTNET, SFM_V2[ChainId.BSC_TESTNET], 9, 'SFM', 'SafeMoon V2'),
  [ChainId.AVALANCHE_FUJI]: new Token(ChainId.AVALANCHE_FUJI, SFM_V2[ChainId.AVALANCHE_FUJI], 9, 'SFM', 'SafeMoon V2'),
  [ChainId.BASE_SEPOLIA]: new Token(ChainId.BASE_SEPOLIA, SFM_V2[ChainId.BASE_SEPOLIA], 9, 'SFM', 'SafeMoon V2'),
  [ChainId.ARB_MAINNET]: new Token(ChainId.ARB_MAINNET, SFM_V2[ChainId.ARB_MAINNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.AVALANCHE_C]: new Token(ChainId.AVALANCHE_C, SFM_V2[ChainId.AVALANCHE_C], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.BASE_MAINNET]: new Token(ChainId.BASE_MAINNET, SFM_V2[ChainId.BASE_MAINNET], 9, 'SFM', 'SafeMoon V2'),
  [ChainId.LINEA]: new Token(ChainId.LINEA, SFM_V2[ChainId.LINEA], 9, 'SFM', 'SafeMoon V2'),
  [ChainId.FANTOM]: new Token(ChainId.FANTOM, SFM_V2[ChainId.FANTOM], 9, 'SFM', 'SafeMoon V2'),
  [ChainId.OPTIMISM]: new Token(ChainId.OPTIMISM, SFM_V2[ChainId.OPTIMISM], 9, 'SFM', 'SafeMoon V2'),
  [ChainId.BLAST]: new Token(ChainId.BLAST, SFM_V2[ChainId.BLAST], 9, 'SFM', 'SafeMoon V2')
}

export const ChainIdHex = {
  MAINNET: '0x1',
  SEPOLIA_TESTNET: '0xaa36a7',
  BSC_MAINNET: '0x38',
  BSC_TESTNET: '0x61',
  POLYGON: '0x89',
  MUMBAI_TESTNET: '0x13882',
  ARB_TESTNET: '0x66eee',
  AVALANCHE_FUJI: '0xA869',
  BASE_SEPOLIA: '0x14A34',
  ARB_MAINNET: '0xa4b1',
  AVALANCHE_C: '0xA86A',
  BASE_MAINNET: '0x2105',
  LINEA: '0xE708',
  FANTOM: '0xfa',
  OPTIMISM: '0xa',
  BLAST: '0x13E31'
}

export const CHAIN_TO_METAMASK: any = {
  [ChainId.BSC_MAINNET]: {
    chainId: ChainIdHex.BSC_MAINNET,
    blockExplorerUrls: ['https://bscscan.com/'],
    chainName: 'BSC Mainnet',
    nativeCurrency: {
      decimals: 18,
      name: 'BNB',
      symbol: 'BNB'
    },
    rpcUrls: [process.env.REACT_APP_BSC_URL]
  },
  [ChainId.POLYGON]: {
    chainId: ChainIdHex.POLYGON,
    blockExplorerUrls: ['https://polygonscan.com/'],
    chainName: 'Polygon',
    nativeCurrency: {
      decimals: 18,
      name: 'POL',
      symbol: 'POL'
    },
    rpcUrls: [process.env.REACT_APP_POLYGON_URL]
  },
  [ChainId.BSC_TESTNET]: {
    chainId: ChainIdHex.BSC_TESTNET,
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
    chainName: 'BSC Testnet',
    nativeCurrency: {
      decimals: 18,
      name: 'BNB',
      symbol: 'BNB'
    },
    rpcUrls: [process.env.REACT_APP_BSC_TESTNET_URL]
  },
  [ChainId.SEPOLIA_TESTNET]: {
    chainId: ChainIdHex.SEPOLIA_TESTNET,
    blockExplorerUrls: ['https://sepolia.etherscan.io'],
    chainName: 'Eth Sepolia',
    nativeCurrency: {
      decimals: 18,
      name: 'Ethereum',
      symbol: 'ETH'
    },
    rpcUrls: [process.env.REACT_APP_ETH_TESTNET_URL]
  },
  [ChainId.MUMBAI_TESTNET]: {
    chainId: ChainIdHex.MUMBAI_TESTNET,
    blockExplorerUrls: ['https://amoy.polygonscan.com'],
    chainName: 'Polygon Amoy',
    nativeCurrency: {
      decimals: 18,
      name: 'Matic',
      symbol: 'MATIC'
    },
    rpcUrls: [process.env.REACT_APP_MUMBAI_URL]
  },
  [ChainId.ARB_TESTNET]: {
    chainId: ChainIdHex.ARB_TESTNET,
    blockExplorerUrls: ['https://sepolia.arbiscan.io'],
    chainName: 'Arbitrum Sepolia',
    nativeCurrency: {
      decimals: 18,
      name: 'Ethereum',
      symbol: 'ETH'
    },
    rpcUrls: [process.env.REACT_APP_ARB_TESTNET_URL]
  },
  [ChainId.AVALANCHE_FUJI]: {
    chainId: ChainIdHex.AVALANCHE_FUJI,
    blockExplorerUrls: ['https://testnet.snowtrace.io'],
    chainName: 'Avalanche Fuji',
    nativeCurrency: {
      decimals: 18,
      name: 'Avalanche',
      symbol: 'AVAX'
    },
    rpcUrls: [process.env.REACT_APP_AVALANCHE_FUJI_RPC_URL]
  },
  [ChainId.BASE_SEPOLIA]: {
    chainId: ChainIdHex.BASE_SEPOLIA,
    blockExplorerUrls: ['https://sepolia.basescan.org'],
    chainName: 'Base Sepolia',
    nativeCurrency: {
      decimals: 18,
      name: 'Ethereum',
      symbol: 'ETH'
    },
    rpcUrls: [process.env.REACT_APP_BASE_SEPOLIA_RPC_URL]
  },
  [ChainId.ARB_MAINNET]: {
    chainId: ChainIdHex.ARB_MAINNET,
    blockExplorerUrls: ['https://arbiscan.io'],
    chainName: 'Arbitrum',
    nativeCurrency: {
      decimals: 18,
      name: 'Ethereum',
      symbol: 'ETH'
    },
    rpcUrls: [process.env.REACT_APP_ARB_MAINNET_URL]
  },
  [ChainId.AVALANCHE_C]: {
    chainId: ChainIdHex.AVALANCHE_C,
    blockExplorerUrls: ['https://snowtrace.io'],
    chainName: 'Avalanche C',
    nativeCurrency: {
      decimals: 18,
      name: 'Avalanche',
      symbol: 'AVAX'
    },
    rpcUrls: [process.env.REACT_APP_AVALANCHE_C_RPC_URL]
  },
  [ChainId.BASE_MAINNET]: {
    chainId: ChainIdHex.BASE_MAINNET,
    blockExplorerUrls: ['https://basescan.org'],
    chainName: 'Base',
    nativeCurrency: {
      decimals: 18,
      name: 'Ethereum',
      symbol: 'ETH'
    },
    rpcUrls: [process.env.REACT_APP_BASE_MAINNET_RPC_URL]
  },
  [ChainId.LINEA]: {
    chainId: ChainIdHex.LINEA,
    blockExplorerUrls: ['https://lineascan.build'],
    chainName: 'Linea',
    nativeCurrency: {
      decimals: 18,
      name: 'Ethereum',
      symbol: 'ETH'
    },
    rpcUrls: [process.env.REACT_APP_LINEA_URL]
  },
  [ChainId.FANTOM]: {
    chainId: ChainIdHex.FANTOM,
    blockExplorerUrls: ['https://ftmscan.com/'],
    chainName: 'Fantom',
    nativeCurrency: {
      decimals: 18,
      name: 'Fantom',
      symbol: 'FTM'
    },
    rpcUrls: [process.env.REACT_APP_FANTOM_RPC_URL]
  },
  [ChainId.OPTIMISM]: {
    chainId: ChainIdHex.OPTIMISM,
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
    chainName: 'Optimism',
    nativeCurrency: {
      decimals: 18,
      name: 'Ethereum',
      symbol: 'ETH'
    },
    rpcUrls: [process.env.REACT_APP_OPTIMISM_RPC_URL]
  },
  [ChainId.BLAST]: {
    chainId: ChainIdHex.BLAST,
    blockExplorerUrls: ['https://blastscan.io'],
    chainName: 'Blast',
    nativeCurrency: {
      decimals: 18,
      name: 'Ethereum',
      symbol: 'ETH'
    },
    rpcUrls: [process.env.REACT_APP_BLAST_RPC_URL]
  }
}

export const consolidation = {
  addresses: {
    v1: SFM_V1,
    v2: SFM_V2,
    migration: MIGRATION
  },
  tokens: {
    v1: SfmV1,
    v2: SfmV2
  }
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, COMP, MKR],
  [ChainId.BSC_MAINNET]: [...WETH_ONLY[ChainId.BSC_MAINNET], BAI, B_USDT, BUSD, B_DAI]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT],
  [ChainId.BSC_MAINNET]: [...WETH_ONLY[ChainId.BSC_MAINNET], BAI, B_USDT, BUSD, B_DAI],
  [ChainId.BSC_TESTNET]: [...WETH_ONLY[ChainId.BSC_TESTNET], T_USDT, T_BUSD, T_DAI]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT],
  [ChainId.BSC_MAINNET]: [...WETH_ONLY[ChainId.BSC_MAINNET], BAI, B_USDT, BUSD, B_DAI],
  [ChainId.BSC_TESTNET]: [...WETH_ONLY[ChainId.BSC_TESTNET], T_USDT, T_BUSD, T_DAI]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
    ],
    [USDC, USDT],
    [DAI, USDT]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: boolean
  mobileOnly?: true
  chainIds: number[]
  hrefIos?: string
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  SAFEMOON_WALLET_TESNET: {
    connector: walletconnectMultiTestnet,
    name: 'VGX Wallet',
    iconName: 'new-logo.svg',
    description: 'Your gateway to defi and the VGX Ecosystem.',
    href: null,
    color: '#008a81',
    chainIds: [
      ChainId.BSC_TESTNET,
      ChainId.SEPOLIA_TESTNET,
      ChainId.MUMBAI_TESTNET,
      ChainId.ARB_TESTNET,
      ChainId.AVALANCHE_FUJI,
      ChainId.BASE_SEPOLIA
    ],
    mobile: true
  },
  SAFEMOON_WALLET: {
    connector: walletconnect,
    name: 'VGX Wallet',
    iconName: 'new-logo.svg',
    description: 'Your gateway to defi and the VGX Ecosystem.',
    href: null,
    color: '#008a81',
    chainIds: [
      ChainId.BSC_MAINNET,
      ChainId.MAINNET,
      ChainId.POLYGON,
      ChainId.ARB_MAINNET,
      ChainId.AVALANCHE_C,
      ChainId.BASE_MAINNET,
      ChainId.LINEA,
      ChainId.FANTOM,
      ChainId.OPTIMISM,
      ChainId.BLAST
    ]
  },
  SAFEMOON_WALLET_MOBILE: {
    connector: injected,
    name: 'VGX Wallet',
    iconName: 'new-logo.svg',
    description: 'Your gateway to defi and the VGX Ecosystem.',
    href: `https://safemoon.com/open-dapp-browser?url=${location.href}`,
    hrefIos: `https://safemoon.com/open-dapp-browser?url=${location.href}`,
    color: '#008a81',
    chainIds: [
      ChainId.BSC_MAINNET,
      ChainId.MAINNET,
      ChainId.POLYGON,
      ChainId.ARB_MAINNET,
      ChainId.AVALANCHE_C,
      ChainId.BASE_MAINNET,
      ChainId.LINEA,
      ChainId.FANTOM,
      ChainId.OPTIMISM,
      ChainId.BLAST
    ],
    mobile: true,
    mobileOnly: true
  },
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
    chainIds: [
      ChainId.MAINNET,
      ChainId.BSC_MAINNET,
      ChainId.BSC_TESTNET,
      ChainId.SEPOLIA_TESTNET,
      ChainId.MUMBAI_TESTNET,
      ChainId.POLYGON,
      ChainId.ARB_TESTNET,
      ChainId.AVALANCHE_FUJI,
      ChainId.BASE_SEPOLIA,
      ChainId.ARB_MAINNET,
      ChainId.AVALANCHE_C,
      ChainId.BASE_MAINNET,
      ChainId.LINEA,
      ChainId.FANTOM,
      ChainId.OPTIMISM,
      ChainId.BLAST
    ]
  },
  WALLETCONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#3375BB',
    chainIds: [
      ChainId.BSC_MAINNET,
      ChainId.MAINNET,
      ChainId.POLYGON,
      ChainId.ARB_MAINNET,
      ChainId.AVALANCHE_C,
      ChainId.BASE_MAINNET,
      ChainId.LINEA,
      ChainId.FANTOM,
      ChainId.OPTIMISM,
      ChainId.BLAST
    ],
    mobile: true
  },

  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
    chainIds: [
      ChainId.MAINNET,
      ChainId.BSC_MAINNET,
      ChainId.BSC_TESTNET,
      ChainId.SEPOLIA_TESTNET,
      ChainId.MUMBAI_TESTNET,
      ChainId.POLYGON,
      ChainId.ARB_TESTNET,
      ChainId.AVALANCHE_FUJI,
      ChainId.BASE_SEPOLIA,
      ChainId.ARB_MAINNET,
      ChainId.AVALANCHE_C,
      ChainId.BASE_MAINNET,
      ChainId.LINEA,
      ChainId.FANTOM,
      ChainId.OPTIMISM,
      ChainId.BLAST
    ],
    mobile: false
  },
  METAMASK_MOBILE: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: `https://metamask.app.link/dapp/${location.href}`,
    hrefIos: `https://metamask.app.link/dapp/${location.href}`,
    color: '#E8831D',
    chainIds: [
      ChainId.MAINNET,
      ChainId.BSC_MAINNET,
      ChainId.BSC_TESTNET,
      ChainId.SEPOLIA_TESTNET,
      ChainId.MUMBAI_TESTNET,
      ChainId.POLYGON,
      ChainId.ARB_TESTNET,
      ChainId.AVALANCHE_FUJI,
      ChainId.BASE_SEPOLIA,
      ChainId.ARB_MAINNET,
      ChainId.AVALANCHE_C,
      ChainId.BASE_MAINNET,
      ChainId.LINEA,
      ChainId.FANTOM,
      ChainId.OPTIMISM,
      ChainId.BLAST
    ],
    mobile: true,
    mobileOnly: true
  },
  TRUSTWALLET_MOBILE: {
    connector: injected,
    name: 'TrustWallet',
    iconName: 'trustwallet.svg',
    description: 'The most trusted & secure crypto wallet',
    href: `https://link.trustwallet.com/open_url?coin_id=60&url=${location.href}`,
    hrefIos: `https://link.trustwallet.com/open_url?coin_id=60&url=${location.href}`,
    color: '#3375BB',
    chainIds: [
      ChainId.BSC_MAINNET,
      ChainId.MAINNET,
      ChainId.POLYGON,
      ChainId.ARB_MAINNET,
      ChainId.AVALANCHE_C,
      ChainId.BASE_MAINNET,
      ChainId.LINEA,
      ChainId.FANTOM,
      ChainId.OPTIMISM,
      ChainId.BLAST
    ],
    mobile: true,
    mobileOnly: true
  },
  TRUSTWALLET_DESKTOP: {
    connector: walletconnect,
    name: 'TrustWallet',
    iconName: 'trustwallet.svg',
    description: 'The most trusted & secure crypto wallet',
    href: null,
    color: '#3375BB',
    chainIds: [
      ChainId.BSC_MAINNET,
      ChainId.MAINNET,
      ChainId.POLYGON,
      ChainId.ARB_MAINNET,
      ChainId.AVALANCHE_C,
      ChainId.BASE_MAINNET,
      ChainId.LINEA,
      ChainId.FANTOM,
      ChainId.OPTIMISM,
      ChainId.BLAST
    ]
  }
}

export const NetworkContextName = 'NETWORK'

export const appEnv: string = process.env.REACT_APP_ENV || 'development'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.multiply(JSBI.BigInt(2), JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16))) // .02 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

export const MAX_PRIORITY_FEE: BigNumberish = BigNumber.from(2).mul(10 ** 9)

export const ETHERSCAN_API_KEY: string = process.env.REACT_APP_ETHERSCAN_API_KEY || ''
export const POLYGON_API_KEY: string = process.env.REACT_APP_POLYGON_API_KEY || ''

// the Safemoon Default token list lives here

export const DEFAULT_TOKEN_LIST_URL: string = process.env.REACT_APP_DEFAULT_TOKEN_LIST_URL || ''

export const MOONPAY_TOKEN_LIST_URL: string = process.env.REACT_APP_MOONPAY_TOKEN_LIST_URL || ''

export const SIGN_MOONPAY_URL: string = process.env.REACT_APP_SIGN_MOONPAY_URL || ''

export const DEFAULT_TOKEN_BLACK_LIST: string = process.env.REACT_APP_DEFAULT_TOKEN_BLACK_LIST_URL || ''

export const DEFAULT_WALLET_BLACK_LIST_URL: string = process.env.REACT_APP_DEFAULT_WALLET_BLACK_LIST_URL || ''

export const CHECK_WALLETADDRESS_BLACKLIST_URL: string = process.env.REACT_APP_CHECK_WALLETADDRESS_BLACKLIST_URL || ''

export const MESSAGE_ALERTS_URL: string = process.env.REACT_APP_MESSAGE_ALERTS_URL || ''

export const SAVE_LOG_URL: string = process.env.REACT_APP_SAVE_LOG_URL || ''

export const SEARCH_TOKENS_URL: string = process.env.REACT_APP_SEARCH_TOKENS_URL || ''

export const CHECK_USA_LOCATION: string = process.env.REACT_APP_CHECK_USA_URL || ''

export const MARKETDATA_URL: string = process.env.REACT_APP_MARKETDATA_URL || ''

export const GET_PRICE_URL: string = process.env.REACT_APP_OX_GET_PRICE || ''
export const GET_QUOTE_URL: string = process.env.REACT_APP_OX_GET_QUOTE || ''

export const BLACKLIST_TOKENS_SAFEMOON_V1 = [
  '0X8076C74C5E3F5852037F31FF0093EEB8C8ADD8D3',
  '0XDD6999EC25948811D7C671051F5B4E44B175239E'
]

export const TOKENS_SAFEMOON_V2 = [
  '0X42981D0BFBAF196529376EE702F2A9EB9092FCB5',
  '0X8DF4AAE3A61BC5092EC332F40378A67A70187D46'
]

export const MOONPAY_KEY = process.env.REACT_APP_MOONPAY_KEY

export const BRIDGES = (type: 'testnet' | 'mainnet') => [
  {
    images: [ArbitrumLogo, vgxLogo],
    chain: 'arb',
    chainId: type === 'testnet' ? ChainId.ARB_TESTNET : ChainId.ARB_TESTNET,
    tokenAddress: VGX_TOKEN[type === 'testnet' ? ChainId.ARB_TESTNET : ChainId.ARB_TESTNET],
    rpcUrl: type === 'testnet' ? process.env.REACT_APP_ARB_TESTNET_URL : process.env.REACT_APP_ARB_TESTNET_URL,
    chainName: 'Arbitrum',
    color: '#8247E5',
    decimals: 8,
    currency: 'ETH',
    shortChain: 'Arbitrum',
    hexChain: type === 'testnet' ? ChainIdHex.ARB_TESTNET : ChainIdHex.ARB_TESTNET,
    network: 4,
    id: 8,
    symbol: 'VGX'
  },
  {
    images: [AvalancheLogo, vgxLogo],
    chain: 'avax',
    chainId: type === 'testnet' ? ChainId.AVALANCHE_FUJI : ChainId.AVALANCHE_FUJI,
    tokenAddress: VGX_TOKEN[type === 'testnet' ? ChainId.AVALANCHE_FUJI : ChainId.AVALANCHE_FUJI],
    rpcUrl:
      type === 'testnet' ? process.env.REACT_APP_AVALANCHE_FUJI_RPC_URL : process.env.REACT_APP_AVALANCHE_FUJI_RPC_URL,
    chainName: 'Avalanche',
    color: '#e84142',
    decimals: 8,
    currency: 'AVAX',
    shortChain: 'Avalanche',
    hexChain: type === 'testnet' ? ChainIdHex.AVALANCHE_FUJI : ChainIdHex.AVALANCHE_FUJI,
    network: 5,
    id: 9,
    symbol: 'VGX'
  },
  {
    images: [BaseLogo, vgxLogo],
    chain: 'base',
    chainId: type === 'testnet' ? ChainId.BASE_SEPOLIA : ChainId.BASE_SEPOLIA,
    tokenAddress: VGX_TOKEN[type === 'testnet' ? ChainId.BASE_SEPOLIA : ChainId.BASE_SEPOLIA],
    rpcUrl:
      type === 'testnet' ? process.env.REACT_APP_BASE_SEPOLIA_RPC_URL : process.env.REACT_APP_BASE_SEPOLIA_RPC_URL,
    chainName: 'Base',
    color: '#33c2ea',
    decimals: 8,
    currency: 'ETH',
    shortChain: 'Base',
    hexChain: type === 'testnet' ? ChainIdHex.BASE_SEPOLIA : ChainIdHex.BASE_SEPOLIA,
    network: 6,
    id: 11,
    symbol: 'VGX'
  },
  {
    images: [BNBLogo, vgxLogo],
    chain: 'bsc',
    chainId: type === 'testnet' ? ChainId.BSC_TESTNET : ChainId.BSC_MAINNET,
    tokenAddress: VGX_TOKEN[type === 'testnet' ? ChainId.BSC_TESTNET : ChainId.BSC_MAINNET],
    rpcUrl: type === 'testnet' ? process.env.REACT_APP_BSC_TESTNET_URL : process.env.REACT_APP_BSC_URL,
    chainName: 'Binance Smart Chain',
    color: '#F3BA2F',
    decimals: 8,
    currency: 'BNB',
    shortChain: 'BSC',
    hexChain: type === 'testnet' ? ChainIdHex.BSC_TESTNET : ChainIdHex.BSC_MAINNET,
    network: 2,
    id: 6,
    symbol: 'VGX'
  },
  {
    images: [EthereumLogo, vgxLogo],
    chain: 'ethereum',
    chainId: type === 'testnet' ? ChainId.SEPOLIA_TESTNET : ChainId.MAINNET,
    tokenAddress: VGX_TOKEN[type === 'testnet' ? ChainId.SEPOLIA_TESTNET : ChainId.MAINNET],
    rpcUrl: type === 'testnet' ? process.env.REACT_APP_ETH_TESTNET_URL : process.env.REACT_APP_ETH_URL,
    chainName: 'Ethereum',
    color: '#DEDEDE',
    decimals: 8,
    currency: 'ETH',
    shortChain: 'ETH',
    hexChain: type === 'testnet' ? ChainIdHex.SEPOLIA_TESTNET : ChainIdHex.MAINNET,
    network: 1,
    id: 5,
    symbol: 'VGX'
  },
  {
    images: [MATICLogo, vgxLogo],
    chain: 'polygon',
    chainId: type === 'testnet' ? ChainId.MUMBAI_TESTNET : ChainId.POLYGON,
    tokenAddress: VGX_TOKEN[type === 'testnet' ? ChainId.MUMBAI_TESTNET : ChainId.POLYGON],
    rpcUrl: type === 'testnet' ? process.env.REACT_APP_MUMBAI_URL : process.env.REACT_APP_POLYGON_URL,
    chainName: 'Polygon',
    color: '#8247E5',
    decimals: 8,
    currency: type === 'testnet' ? 'MATIC' : 'POL',
    shortChain: 'Polygon',
    hexChain: type === 'testnet' ? ChainIdHex.MUMBAI_TESTNET : ChainIdHex.POLYGON,
    network: 3,
    id: 7,
    symbol: 'VGX'
  },
  {
    images: [ArbitrumLogo, safemoonLogo],
    chain: 'arb',
    chainId: type === 'testnet' ? ChainId.ARB_TESTNET : ChainId.ARB_TESTNET,
    tokenAddress: SFM_V2[type === 'testnet' ? ChainId.ARB_TESTNET : ChainId.ARB_TESTNET],
    rpcUrl: type === 'testnet' ? process.env.REACT_APP_ARB_TESTNET_URL : process.env.REACT_APP_ARB_TESTNET_URL,
    chainName: 'Arbitrum',
    color: '#8247E5',
    decimals: 9,
    currency: 'ETH',
    shortChain: 'Arbitrum',
    hexChain: type === 'testnet' ? ChainIdHex.ARB_TESTNET : ChainIdHex.ARB_TESTNET,
    network: 4,
    id: 4,
    symbol: 'SFM'
  },
  {
    images: [AvalancheLogo, safemoonLogo],
    chain: 'avax',
    chainId: type === 'testnet' ? ChainId.AVALANCHE_FUJI : ChainId.AVALANCHE_FUJI,
    tokenAddress: SFM_V2[type === 'testnet' ? ChainId.AVALANCHE_FUJI : ChainId.AVALANCHE_FUJI],
    rpcUrl:
      type === 'testnet' ? process.env.REACT_APP_AVALANCHE_FUJI_RPC_URL : process.env.REACT_APP_AVALANCHE_FUJI_RPC_URL,
    chainName: 'Avalanche',
    color: '#e84142',
    decimals: 9,
    currency: 'ETH',
    shortChain: 'Avalanche',
    hexChain: type === 'testnet' ? ChainIdHex.AVALANCHE_FUJI : ChainIdHex.AVALANCHE_FUJI,
    network: 5,
    id: 10,
    symbol: 'SFM'
  },
  {
    images: [BaseLogo, safemoonLogo],
    chain: 'avax',
    chainId: type === 'testnet' ? ChainId.BASE_SEPOLIA : ChainId.BASE_SEPOLIA,
    tokenAddress: SFM_V2[type === 'testnet' ? ChainId.BASE_SEPOLIA : ChainId.BASE_SEPOLIA],
    rpcUrl:
      type === 'testnet' ? process.env.REACT_APP_BASE_SEPOLIA_RPC_URL : process.env.REACT_APP_BASE_SEPOLIA_RPC_URL,
    chainName: 'Base',
    color: '#33c2ea',
    decimals: 9,
    currency: 'ETH',
    shortChain: 'Base',
    hexChain: type === 'testnet' ? ChainIdHex.BASE_SEPOLIA : ChainIdHex.BASE_SEPOLIA,
    network: 6,
    id: 12,
    symbol: 'SFM'
  },
  {
    images: [BNBLogo, safemoonLogo],
    chain: 'bsc',
    chainId: type === 'testnet' ? ChainId.BSC_TESTNET : ChainId.BSC_MAINNET,
    tokenAddress: SFM_V2[type === 'testnet' ? ChainId.BSC_TESTNET : ChainId.BSC_MAINNET],
    rpcUrl: type === 'testnet' ? process.env.REACT_APP_BSC_TESTNET_URL : process.env.REACT_APP_BSC_URL,
    chainName: 'Binance Smart Chain',
    color: '#F3BA2F',
    decimals: 9,
    currency: 'BNB',
    shortChain: 'BSC',
    hexChain: type === 'testnet' ? ChainIdHex.BSC_TESTNET : ChainIdHex.BSC_MAINNET,
    network: 2,
    id: 2,
    symbol: 'SFM'
  },
  {
    images: [EthereumLogo, safemoonLogo],
    chain: 'ethereum',
    chainId: type === 'testnet' ? ChainId.SEPOLIA_TESTNET : ChainId.MAINNET,
    tokenAddress: SFM_V2[type === 'testnet' ? ChainId.SEPOLIA_TESTNET : ChainId.MAINNET],
    rpcUrl: type === 'testnet' ? process.env.REACT_APP_ETH_TESTNET_URL : process.env.REACT_APP_ETH_URL,
    chainName: 'Ethereum',
    color: '#DEDEDE',
    decimals: 9,
    currency: 'ETH',
    shortChain: 'ETH',
    hexChain: type === 'testnet' ? ChainIdHex.SEPOLIA_TESTNET : ChainIdHex.MAINNET,
    network: 1,
    id: 1,
    symbol: 'SFM'
  },
  {
    images: [MATICLogo, safemoonLogo],
    chain: 'polygon',
    chainId: type === 'testnet' ? ChainId.MUMBAI_TESTNET : ChainId.POLYGON,
    tokenAddress: SFM_V2[type === 'testnet' ? ChainId.MUMBAI_TESTNET : ChainId.POLYGON],
    rpcUrl: type === 'testnet' ? process.env.REACT_APP_MUMBAI_URL : process.env.REACT_APP_POLYGON_URL,
    chainName: 'Polygon',
    color: '#8247E5',
    decimals: 9,
    currency: type === 'testnet' ? 'MATIC' : 'POL',
    shortChain: 'Polygon',
    hexChain: type === 'testnet' ? ChainIdHex.MUMBAI_TESTNET : ChainIdHex.POLYGON,
    network: 3,
    id: 3,
    symbol: 'SFM'
  }
]

const VGX_TOKEN = {
  [ChainId.BSC_TESTNET]: process.env.REACT_APP_VGX_BSC_TESTNET_TOKEN,
  [ChainId.BSC_MAINNET]: '',
  [ChainId.SEPOLIA_TESTNET]: process.env.REACT_APP_VGX_SEPOLIA_TESTNET_TOKEN,
  [ChainId.MAINNET]: '',
  [ChainId.MUMBAI_TESTNET]: process.env.REACT_APP_VGX_MUMBAI_TESTNET_TOKEN,
  [ChainId.POLYGON]: '',
  [ChainId.ARB_TESTNET]: process.env.REACT_APP_VGX2_ARB_TESTNET_TOKEN,
  [ChainId.AVALANCHE_FUJI]: process.env.REACT_APP_VGX_AVALANCHE_FUJI_TOKEN,
  [ChainId.BASE_SEPOLIA]: process.env.REACT_APP_VGX_BASE_SEPOLIA_TOKEN
}

export const getChainType = (chainId: number) => {
  return chainId === ChainId.SEPOLIA_TESTNET ||
    chainId === ChainId.BSC_TESTNET ||
    chainId === ChainId.MUMBAI_TESTNET ||
    chainId === ChainId.ARB_TESTNET ||
    chainId === ChainId.AVALANCHE_FUJI ||
    chainId === ChainId.BASE_SEPOLIA
    ? 'testnet'
    : 'mainnet'
}

export const BRIDGE_CONTRACT: any = {
  [ChainId.BSC_TESTNET]: process.env.REACT_APP_BSC_TESTNET_BRIDGE_CONTRACT,
  [ChainId.SEPOLIA_TESTNET]: process.env.REACT_APP_SEPOLIA_BRIDGE_CONTRACT,
  [ChainId.MUMBAI_TESTNET]: process.env.REACT_APP_MUMBAI_BRIDGE_CONTRACT,
  [ChainId.BSC_MAINNET]: process.env.REACT_APP_BSC_MAINNET_BRIDGE_CONTRACT,
  [ChainId.MAINNET]: process.env.REACT_APP_MAINNET_BRIDGE_CONTRACT,
  [ChainId.POLYGON]: process.env.REACT_APP_POLYGON_BRIDGE_CONTRACT,
  [ChainId.ARB_TESTNET]: process.env.REACT_APP_ARB_TESTNET_BRIDGE_CONTRACT,
  [ChainId.AVALANCHE_FUJI]: process.env.REACT_APP_AVALANCHE_FUJI_BRIDGE_CONTRACT,
  [ChainId.BASE_SEPOLIA]: process.env.REACT_APP_BASE_SEPOLIA_BRIDGE_CONTRACT
}

export const BRIDGE_API_URL = process.env.REACT_APP_BRIDGE_API_URL

export const HEX_CHAINS: any = {
  [ChainId.SEPOLIA_TESTNET]: ChainIdHex.SEPOLIA_TESTNET,
  [ChainId.BSC_TESTNET]: ChainIdHex.BSC_TESTNET,
  [ChainId.MUMBAI_TESTNET]: ChainIdHex.MUMBAI_TESTNET,
  [ChainId.ARB_TESTNET]: ChainIdHex.ARB_TESTNET,
  [ChainId.AVALANCHE_FUJI]: ChainIdHex.AVALANCHE_FUJI,
  [ChainId.BASE_SEPOLIA]: ChainIdHex.BASE_SEPOLIA,
  [ChainId.MAINNET]: ChainIdHex.MAINNET,
  [ChainId.BSC_MAINNET]: ChainIdHex.BSC_MAINNET,
  [ChainId.POLYGON]: ChainIdHex.POLYGON,
  [ChainId.ARB_MAINNET]: ChainIdHex.ARB_MAINNET,
  [ChainId.AVALANCHE_C]: ChainIdHex.AVALANCHE_C,
  [ChainId.BASE_MAINNET]: ChainIdHex.BASE_MAINNET,
  [ChainId.LINEA]: ChainIdHex.LINEA,
  [ChainId.FANTOM]: ChainIdHex.FANTOM,
  [ChainId.OPTIMISM]: ChainIdHex.OPTIMISM,
  [ChainId.BLAST]: ChainIdHex.BLAST
}

export const BINANCE_NETWORK: any = {
  1: 'eth-mainnet',
  56: 'bsc-mainnet',
  137: ''
}

export const LPF_ADDRESS: ChainAddress = {
  [ChainId.MAINNET]: '',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.BSC_MAINNET]: '',
  [ChainId.ROPSTEN]: '',
  [ChainId.BSC_TESTNET]: '0x8f479dc5E44E0d709f8aA5C74715D834A98c4510',
  [ChainId.MUMBAI_TESTNET]: '0x730cee2D0EDaCB5b4f9D8c4944049B12E055Be18',
  [ChainId.POLYGON]: '',
  [ChainId.SEPOLIA_TESTNET]: '0xe6723391368A586f71ABA9bC48740071A7eA6949',
  [ChainId.ARB_TESTNET]: '0x56a06fFE78CA102278c664b90DAd64238Ae180B8',
  [ChainId.AVALANCHE_FUJI]: '0xd8eb2Da3cC872c4c6a2FB4AE12Eedbf9B4caABaE',
  [ChainId.BASE_SEPOLIA]: '0x39E99d408f04c9Ed41239C7D05C5ed2683D06E5b',
  [ChainId.ARB_MAINNET]: '',
  [ChainId.AVALANCHE_C]: '',
  [ChainId.BASE_MAINNET]: '',
  [ChainId.LINEA]: '',
  [ChainId.FANTOM]: '',
  [ChainId.OPTIMISM]: '',
  [ChainId.BLAST]: ''
}

export const POLYGON_EXCHANGE_PROXY = '0xDef1C0ded9bec7F1a1670819833240f027b25EfF'
export const ETHEREUM_EXCHANGE_PROXY = '0xdef1c0ded9bec7f1a1670819833240f027b25eff'
export const ARBITRUM_EXCHANGE_PROXY = '0xdef1c0ded9bec7f1a1670819833240f027b25eff'
