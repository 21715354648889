import { InjectedConnector } from '@web3-react/injected-connector'
import { BinanceConnector } from '@bscswap/binance-connector'
import { WalletConnectConnector } from './WalletConnector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import { ChainId } from '@safemoon/sdk'

const env = process.env.REACT_APP_ENV
const BSC_TESTNET_URL = process.env.REACT_APP_BSC_TESTNET_URL
const ETH_TESTNET_URL = process.env.REACT_APP_ETH_TESTNET_URL
const BSC_URL = process.env.REACT_APP_BSC_URL
const ETH_URL = process.env.REACT_APP_ETH_URL
const POLYGON_URL = process.env.REACT_APP_POLYGON_URL
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID
const ARB_TESTNET_URL = process.env.REACT_APP_ARB_TESTNET_URL
const AVALANCHE_FUJI_URL = process.env.REACT_APP_AVALANCHE_FUJI_RPC_URL
const BASE_SEPOLIA_URL = process.env.REACT_APP_BASE_SEPOLIA_RPC_URL

const ARB_MAINNET_URL = process.env.REACT_APP_ARB_MAINNET_URL
const AVALANCHE_C_URL = process.env.REACT_APP_AVALANCHE_C_RPC_URL
const BASE_MAINNET_URL = process.env.REACT_APP_BASE_MAINNET_RPC_URL
const LINEA_URL = process.env.REACT_APP_LINEA_URL
const FANTOM_URL = process.env.REACT_APP_FANTOM_RPC_URL
const OPTIMISM_URL = process.env.REACT_APP_OPTIMISM_RPC_URL
const BLAST_URL = process.env.REACT_APP_BLAST_RPC_URL

if (typeof BSC_URL === 'undefined' || typeof ETH_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const networkTestnet = new NetworkConnector({
  urls: {
    [ChainId.BSC_TESTNET]: BSC_TESTNET_URL || ''
  }
})

export const network = new NetworkConnector({
  urls: {
    [ChainId.BSC_MAINNET]: BSC_URL || ''
  }
})

export const networkSepolia = new NetworkConnector({
  urls: {
    [ChainId.SEPOLIA_TESTNET]: ETH_TESTNET_URL || ''
  }
})

export const networkEth = new NetworkConnector({
  urls: {
    [ChainId.MAINNET]: ETH_URL
  }
})

export const networkPolygon = new NetworkConnector({
  urls: {
    [ChainId.POLYGON]: POLYGON_URL || ''
  }
})

export const networkAmoy = new NetworkConnector({
  urls: {
    [ChainId.POLYGON]: process.env.REACT_APP_MUMBAI_URL || ''
  }
})

export const networkArbitrum = new NetworkConnector({
  urls: {
    [ChainId.ARB_TESTNET]: ARB_TESTNET_URL || ''
  }
})

export const networkAvalanche = new NetworkConnector({
  urls: {
    [ChainId.AVALANCHE_FUJI]: AVALANCHE_FUJI_URL || ''
  }
})

export const networkBase = new NetworkConnector({
  urls: {
    [ChainId.BASE_SEPOLIA]: BASE_SEPOLIA_URL || ''
  }
})

export const networkArbitrumMainnet = new NetworkConnector({
  urls: {
    [ChainId.ARB_MAINNET]: ARB_MAINNET_URL || ''
  }
})

export const networkAvalancheC = new NetworkConnector({
  urls: {
    [ChainId.AVALANCHE_C]: AVALANCHE_C_URL || ''
  }
})

export const networkBaseMainnet = new NetworkConnector({
  urls: {
    [ChainId.BASE_MAINNET]: BASE_MAINNET_URL || ''
  }
})

export const networkLinea = new NetworkConnector({
  urls: {
    [ChainId.LINEA]: LINEA_URL || ''
  }
})

export const networkFantom = new NetworkConnector({
  urls: {
    [ChainId.FANTOM]: FANTOM_URL || ''
  }
})

export const networkOptimism = new NetworkConnector({
  urls: {
    [ChainId.OPTIMISM]: OPTIMISM_URL || ''
  }
})

export const networkBlast = new NetworkConnector({
  urls: {
    [ChainId.BLAST]: BLAST_URL || ''
  }
})

export const injected = new InjectedConnector({
  supportedChainIds: [
    ChainId.MAINNET,
    3,
    4,
    5,
    42,
    ChainId.BSC_MAINNET,
    ChainId.BSC_TESTNET,
    ChainId.SEPOLIA_TESTNET,
    ChainId.MUMBAI_TESTNET,
    ChainId.POLYGON,
    ChainId.ARB_TESTNET,
    ChainId.AVALANCHE_FUJI,
    ChainId.BASE_SEPOLIA,
    ChainId.ARB_MAINNET,
    ChainId.AVALANCHE_C,
    ChainId.BASE_MAINNET,
    ChainId.LINEA,
    ChainId.FANTOM,
    ChainId.OPTIMISM,
    ChainId.BLAST
  ]
})

export const binanceinjected = new BinanceConnector({
  supportedChainIds: [56, 97, 1]
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.BSC_MAINNET]
})

export const walletconnectBSC = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.BSC_MAINNET, ChainId.MAINNET, ChainId.POLYGON]
})

// mainnet only
export const walletconnectPolygon = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.POLYGON, ChainId.BSC_MAINNET, ChainId.MAINNET]
})

// mainnet only
export const walletconnectTestnet = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.SEPOLIA_TESTNET, ChainId.BSC_TESTNET, ChainId.MUMBAI_TESTNET]
})

export const walletconnectBSCTestnet = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.BSC_TESTNET, ChainId.SEPOLIA_TESTNET, ChainId.MUMBAI_TESTNET]
})

// mainnet only
export const walletconnectPolygonTestnet = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.MUMBAI_TESTNET, ChainId.BSC_TESTNET, ChainId.SEPOLIA_TESTNET]
})

export const walletconnectMultiTestnet = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [
    ChainId.BSC_TESTNET,
    ChainId.SEPOLIA_TESTNET,
    ChainId.MUMBAI_TESTNET,
    ChainId.ARB_TESTNET,
    ChainId.AVALANCHE_FUJI,
    ChainId.BASE_SEPOLIA
  ]
})

// mainnet only
export const trustwallet = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.MAINNET, ChainId.BSC_MAINNET, ChainId.POLYGON]
})

export const trustwalletBSC = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.BSC_MAINNET, ChainId.MAINNET, ChainId.POLYGON]
})

// mainnet only
export const trustwalletPolygon = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.POLYGON, ChainId.BSC_MAINNET, ChainId.MAINNET]
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: ETH_URL,
  appName: 'SafemoonSwap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg'
})

export const activeNetwork = (chainId: ChainId, activate: any) => {
  if (chainId === ChainId.MAINNET) {
    activate(networkEth)
  } else if (chainId === ChainId.SEPOLIA_TESTNET) {
    activate(networkSepolia)
  } else if (chainId === ChainId.BSC_MAINNET) {
    activate(network)
  } else if (chainId === ChainId.BSC_TESTNET) {
    activate(networkTestnet)
  } else if (chainId === ChainId.POLYGON) {
    activate(networkPolygon)
  } else if (chainId === ChainId.MUMBAI_TESTNET) {
    activate(networkAmoy)
  } else if (chainId === ChainId.ARB_TESTNET) {
    activate(networkArbitrum)
  } else if (chainId === ChainId.AVALANCHE_FUJI) {
    activate(networkAvalanche)
  } else if (chainId === ChainId.BASE_SEPOLIA) {
    activate(networkBase)
  } else if (chainId === ChainId.ARB_MAINNET) {
    activate(networkArbitrumMainnet)
  } else if (chainId === ChainId.AVALANCHE_C) {
    activate(networkAvalancheC)
  } else if (chainId === ChainId.BASE_MAINNET) {
    activate(networkBaseMainnet)
  } else if (chainId === ChainId.LINEA) {
    activate(networkLinea)
  } else if (chainId === ChainId.FANTOM) {
    activate(networkFantom)
  } else if (chainId === ChainId.OPTIMISM) {
    activate(networkOptimism)
  } else if (chainId === ChainId.BLAST) {
    activate(networkBlast)
  }
}
