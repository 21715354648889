import React, { useMemo } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { darken, lighten } from 'polished'
import { Activity } from 'react-feather'
import useENSName from '../../hooks/useENSName'
import { useHasSocks } from '../../hooks/useSocksBalance'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'

import Identicon from '../Identicon'
import PortisIcon from '../../assets/images/portisIcon.png'
import WalletModal from '../WalletModal'
import { ButtonSecondary } from '../Button'
import BinanceIcon from '../../assets/images/bnb.svg'
import FortmaticIcon from '../../assets/images/fortmaticIcon.png'
import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg'
import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg'

import { RowBetween } from '../Row'
import { shortenAddress } from '../../utils'
import { useAllTransactions } from '../../state/transactions/hooks'
import { NetworkContextName } from '../../constants'
import {
  injected,
  binanceinjected,
  walletconnect,
  walletlink,
  fortmatic,
  portis,
  walletconnectBSC,
  walletconnectPolygon
} from '../../connectors'
import Loader from '../Loader'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { getWalletConnectName } from '../AccountDetails'
import { useActiveWeb3React } from '../../hooks'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem 1.5rem;
  height: 40px;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.5rem 0.75rem;
  `}
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean; isConnected?: boolean }>`
  background-color: ${({ pending, theme, isConnected }) =>
    pending ? theme.primary1 : isConnected ? theme.bgGray : theme.primaryMain};
  border: 1px solid
    ${({ pending, theme, isConnected }) => (pending ? theme.primary1 : isConnected ? theme.bgGray : theme.primaryMain)};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
  font-weight: ${({ isConnected }) => (isConnected ? 400 : 'bold')};
  font-size: 16px;
  color: ${({ isConnected }) => (isConnected ? '#ffffff' : '#0b0a0f')};
  border-radius: 24px;
  height: 100%;
  min-width: 218px;
  :hover,
  :focus {
    background-color: ${({ pending, theme, isConnected }) =>
      pending ? darken(0.05, theme.primary1) : lighten(0.05, isConnected ? theme.bgGray : theme.primaryMain)};
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTranscationsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function recentTransactionsOnly(a: TransactionDetails) {
  return new Date().getTime() - a.addedTime < 86_400_000
}

const SOCK = (
  <span role="img" aria-label="has socks emoji" style={{ marginTop: -4, marginBottom: -4 }}>
    🧦
  </span>
)

export default function Web3Status() {
  const { t } = useTranslation()
  const { active, account, connector, error } = useActiveWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)
  const query = useParsedQueryString()

  const { ENSName } = useENSName(account)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(recentTransactionsOnly).sort(newTranscationsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  const hasPendingTransactions = !!pending.length
  const hasSocks = useHasSocks()
  const toggleWalletModal = useWalletModalToggle()

  const { name: walletName, icons } = getWalletConnectName(connector)

  // handle the logo we want to show with the account
  function getStatusIcon() {
    if (connector === injected) {
      return <Identicon />
    } else if (connector === binanceinjected) {
      return (
        <IconWrapper size={16}>
          <img src={BinanceIcon} alt={''} />
        </IconWrapper>
      )
    } else if (connector === walletconnect || connector === walletconnectBSC || connector === walletconnectPolygon) {
      return walletName === 'VGX Wallet' ? (
        <IconWrapper size={16}>
          <img src={'/images/new-logo.svg'} alt={'wallet connect logo'} />
        </IconWrapper>
      ) : (
        <IconWrapper size={16}>
          <img src={icons && icons?.length > 0 ? icons[0] : WalletConnectIcon} alt={'wallet connect logo'} />
        </IconWrapper>
      )
    } else if (connector === walletlink) {
      return (
        <IconWrapper size={16}>
          <img src={CoinbaseWalletIcon} alt={''} />
        </IconWrapper>
      )
    } else if (connector === fortmatic) {
      return (
        <IconWrapper size={16}>
          <img src={FortmaticIcon} alt={''} />
        </IconWrapper>
      )
    } else if (connector === portis) {
      return (
        <IconWrapper size={16}>
          <img src={PortisIcon} alt={''} />
        </IconWrapper>
      )
    }
  }

  function getWeb3Status() {
    if (account) {
      return isMobile || query.viewMode === 'mobile' ? (
        <div className="cursor-pointer" onClick={toggleWalletModal}>
          <img src="/images/swap_walleticon.svg" alt="ic" className="w-[48px] ml-[12px]" />
        </div>
      ) : (
        <Web3StatusConnected
          id="web3-status-connected"
          onClick={toggleWalletModal}
          pending={hasPendingTransactions}
          isConnected={true}
        >
          {hasPendingTransactions ? (
            <RowBetween>
              <Text>{pending?.length} Pending</Text> <Loader stroke="white" />
            </RowBetween>
          ) : (
            <>
              {hasSocks ? SOCK : null}
              <p className="mt-[4px] mb-0 mr-[10px] text-[16px]">{ENSName || shortenAddress(account)}</p>
            </>
          )}
          {!hasPendingTransactions && getStatusIcon()}
        </Web3StatusConnected>
      )
    } else if (error) {
      return (
        <Web3StatusError onClick={toggleWalletModal}>
          <NetworkIcon />
          <Text>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</Text>
        </Web3StatusError>
      )
    } else {
      return (
        <Web3StatusConnected id="connect-wallet" onClick={toggleWalletModal} className={isMobile && '!min-w-[auto]'}>
          <Text style={{ fontWeight: 'bold' }}>{isMobile ? 'Connect' : t('connectToWallet')}</Text>
        </Web3StatusConnected>
      )
    }
  }

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      {getWeb3Status()}
      <WalletModal ENSName={ENSName} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
