import { ChainId } from '@safemoon/sdk'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { Text } from 'rebass'
// import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

import styled from 'styled-components'

// import Logo from '../../assets/images/SafeMoon-Logo.png'
// import LogoType from '../../assets/images/SafeMoonSwap-LogoType.svg'
import { useActiveWeb3React } from '../../hooks'

// import Settings from '../Settings'

import { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
import {
  useGetModals,
  useWalletModalChainId,
  useWalletModalOpen,
  useWalletModalToggle,
  useWrongNetwork
} from '../../state/application/hooks'
import classNames from 'classnames'
import { StyledDropDown } from '../CurrencyInputPanel'
import Web3 from 'web3'
import { activeNetwork, binanceinjected, injected } from '../../connectors'
import { WalletConnectConnector } from '../../connectors/WalletConnector'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { useHistory, useLocation } from 'react-router-dom'
import { BINANCE_NETWORK, HEX_CHAINS, MARKETDATA_URL, SFM_V2 } from '../../constants'
import { WrongNetwork } from '../WalletModal'
import { convertObjectToSearchParams, handleSwitchChainInjected } from '../../utils'
// import SwitchField from '../SwitchField/SwitchField'
// import { useEnableTestnets } from '../../state/user/hooks'

import bscIcon from '../../assets/images/bsc.svg'
import ethIcon from '../../assets/images/ethereum-chain.svg'
import polygonIcon from '../../assets/images/polygon.svg'
import avalancheIcon from '../../assets/images/avalanche.png'

import bscTestnetIcon from '../../assets/images/bsc-testnet.svg'
import ethSepoliaIcon from '../../assets/images/eth-sepolia.svg'
import amoyIcon from '../../assets/images/amoy.svg'
import sepoliaArbIcon from '../../assets/images/sepolia-arb.svg'
import fujiIcon from '../../assets/images/fuji.svg'
import sepoliaBaseIcon from '../../assets/images/sepolia-base.svg'
import ftmIcon from '../../assets/images/ftm.png'
import lineaIcon from '../../assets/images/linea.png'
import optimismIcon from '../../assets/images/optimism.png'
import blastIcon from '../../assets/images/blast.png'
import axios from 'axios'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 3;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    // padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
    top: 0 !important;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const AccountElement = styled.div<{ active: boolean; viewMode?: any }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, viewMode }) => (viewMode === 'mobile' ? theme.bgDark : '#000000')};
  border-radius: 24px;
  white-space: nowrap;
  width: 100%;
  height: 48px;

  :focus {
    border: 1px solid blue;
  }
`

export const NETWORK_ICON: any = {
  [ChainId.MAINNET]: ethIcon,
  [ChainId.BSC_MAINNET]: bscIcon,
  [ChainId.BSC_TESTNET]: bscTestnetIcon,
  [ChainId.SEPOLIA_TESTNET]: ethSepoliaIcon,
  [ChainId.MUMBAI_TESTNET]: amoyIcon,
  [ChainId.POLYGON]: polygonIcon,
  [ChainId.ARB_TESTNET]: sepoliaArbIcon,
  [ChainId.AVALANCHE_FUJI]: fujiIcon,
  [ChainId.BASE_SEPOLIA]: sepoliaBaseIcon,
  [ChainId.ARB_MAINNET]: sepoliaArbIcon,
  [ChainId.AVALANCHE_C]: avalancheIcon,
  [ChainId.BASE_MAINNET]: sepoliaBaseIcon,
  [ChainId.LINEA]: lineaIcon,
  [ChainId.FANTOM]: ftmIcon,
  [ChainId.OPTIMISM]: optimismIcon,
  [ChainId.BLAST]: blastIcon
}

const NETWORK_LABELS: any = {
  [ChainId.MAINNET]: 'Ethereum',
  [ChainId.RINKEBY]: 'Wrong Network',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Wrong Network',
  [ChainId.KOVAN]: 'Wrong Network',
  [ChainId.BSC_MAINNET]: 'BSC Mainnet',
  [ChainId.BSC_TESTNET]: 'BSC Testnet',
  [ChainId.SEPOLIA_TESTNET]: 'Eth Sepolia',
  [ChainId.MUMBAI_TESTNET]: 'Polygon Amoy',
  [ChainId.POLYGON]: 'Polygon',
  [ChainId.ARB_TESTNET]: 'Arbitrum Sepolia',
  [ChainId.AVALANCHE_FUJI]: 'Avalanche Fuji',
  [ChainId.BASE_SEPOLIA]: 'Base Sepolia',
  [ChainId.ARB_MAINNET]: 'Arbitrum',
  [ChainId.AVALANCHE_C]: 'Avalanche C',
  [ChainId.BASE_MAINNET]: 'Base',
  [ChainId.LINEA]: 'Linea',
  [ChainId.FANTOM]: 'Fantom',
  [ChainId.OPTIMISM]: 'Optimism',
  [ChainId.BLAST]: 'Blast'
}

const SHORT_NETWORK_LABELS: any = {
  [ChainId.MAINNET]: 'ETH',
  [ChainId.RINKEBY]: 'Wrong Network',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Wrong Network',
  [ChainId.KOVAN]: 'Wrong Network',
  [ChainId.BSC_MAINNET]: 'BSC',
  [ChainId.BSC_TESTNET]: 'BSC',
  [ChainId.SEPOLIA_TESTNET]: 'Sepolia',
  [ChainId.MUMBAI_TESTNET]: 'Amoy',
  [ChainId.POLYGON]: 'Polygon',
  [ChainId.ARB_TESTNET]: 'Sepolia-Arb',
  [ChainId.AVALANCHE_FUJI]: 'Fuji',
  [ChainId.BASE_SEPOLIA]: 'Sepolia-Base',
  [ChainId.ARB_MAINNET]: 'Arbitrum',
  [ChainId.AVALANCHE_C]: 'Avalanche C',
  [ChainId.BASE_MAINNET]: 'Base',
  [ChainId.LINEA]: 'Linea',
  [ChainId.FANTOM]: 'Fantom',
  [ChainId.OPTIMISM]: 'Optimism',
  [ChainId.BLAST]: 'Blast'
}

const NETWORKS_MAINNET = [
  {
    name: 'BSC Mainnet',
    shortName: 'BSC',
    chainId: ChainId.BSC_MAINNET,
    icon: bscIcon
  },
  {
    name: 'Ethereum',
    shortName: 'ETH',
    chainId: ChainId.MAINNET,
    icon: ethIcon
  },
  {
    name: 'Polygon',
    shortName: 'Polygon',
    chainId: ChainId.POLYGON,
    icon: polygonIcon
  },
  {
    name: 'Arbitrum',
    shortName: 'Arbitrum',
    chainId: ChainId.ARB_MAINNET,
    icon: sepoliaArbIcon
  },
  {
    name: 'Avalanche C',
    shortName: 'Avalanche C',
    chainId: ChainId.AVALANCHE_C,
    icon: avalancheIcon
  },
  {
    name: 'Base',
    shortName: 'Base',
    chainId: ChainId.BASE_MAINNET,
    icon: sepoliaBaseIcon
  },
  {
    name: 'Linea',
    shortName: 'Linea',
    chainId: ChainId.LINEA,
    icon: lineaIcon
  },
  // {
  //   name: 'Fantom',
  //   shortName: 'Fantom',
  //   chainId: ChainId.FANTOM,
  //   icon: ftmIcon
  // },
  {
    name: 'Optimism',
    shortName: 'Optimism',
    chainId: ChainId.OPTIMISM,
    icon: optimismIcon
  },
  {
    name: 'Blast',
    shortName: 'Blast',
    chainId: ChainId.BLAST,
    icon: blastIcon
  }
]

const NETWORKS_TESTNET = [
  {
    name: 'BSC testnet',
    shortName: 'BSC',
    chainId: ChainId.BSC_TESTNET,
    icon: bscTestnetIcon
  },
  {
    name: 'Eth Sepolia',
    shortName: 'Sepolia',
    chainId: ChainId.SEPOLIA_TESTNET,
    icon: ethSepoliaIcon
  },
  {
    name: 'Polygon Amoy',
    shortName: 'Amoy',
    chainId: ChainId.MUMBAI_TESTNET,
    icon: amoyIcon
  },
  {
    name: 'Arbitrum Sepolia',
    shortName: 'Sepolia-Arb',
    chainId: ChainId.ARB_TESTNET,
    icon: sepoliaArbIcon
  },
  {
    name: 'Avalanche Fuji',
    shortName: 'Fuji',
    chainId: ChainId.AVALANCHE_FUJI,
    icon: fujiIcon
  },
  {
    name: 'Base Sepolia',
    shortName: 'Sepolia-Base',
    chainId: ChainId.BASE_SEPOLIA,
    icon: sepoliaBaseIcon
  }
]

const SelectNetwork = ({ viewMode, showBalance }: { viewMode?: any; showBalance?: any }) => {
  const [showMenus, setShowMenus] = useState(false)
  const ref = useRef(null)
  const { chainId: currentChainId, activate, connector } = useActiveWeb3React()

  const toggleWalletModal = useWalletModalToggle()
  const walletModalOpen = useWalletModalOpen()
  const [chainId, setChainId] = useWalletModalChainId(currentChainId)
  const { toggleWrongNetwork } = useWrongNetwork()
  // const [enableTestnets, setEnableTestnets] = useEnableTestnets()

  const enableTestnets = false

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !(ref.current as any)?.contains(event.target)) {
        setShowMenus(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  const history = useHistory()
  const location = useLocation()

  const handleChangeNetwork = async selectedChainId => {
    if (connector instanceof WalletConnectConnector) {
      connector.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: HEX_CHAINS[selectedChainId] }]
      })
      if (location.pathname?.indexOf('/swap') !== -1 && viewMode !== 'mobile') {
        history.push('/swap')
      }
      setShowMenus(false)
      return
    }
    if (window.ethereum && connector === injected) {
      if (chainId !== selectedChainId) {
        if (viewMode === 'mobile' && location.pathname?.indexOf('/swap') !== -1) {
          history.push(`/swap?viewMode=mobile&showBalance=${showBalance}`)
        }

        const web3 = new Web3(window.ethereum as any)
        await handleSwitchChainInjected(selectedChainId)
        const accounts = await web3.eth.getAccounts()
        if (accounts[0]) {
          activate(injected, undefined, true).catch(error => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }
    } else if (connector === binanceinjected && window.BinanceChain) {
      if (+selectedChainId !== ChainId.BSC_MAINNET && +selectedChainId !== ChainId.MAINNET) {
        toggleWrongNetwork()
      } else {
        await window.BinanceChain?.switchNetwork(BINANCE_NETWORK[selectedChainId])
        activate(binanceinjected, undefined, true).catch(error => {
          console.error('Failed to activate after accounts changed', error)
        })
      }
    } else {
      if (walletModalOpen) {
        setChainId(selectedChainId)
      } else {
        activeNetwork(chainId, activate)

        toggleWalletModal(selectedChainId)
      }
    }

    if (location.pathname?.indexOf('/swap') !== -1 && viewMode !== 'mobile') {
      history.push('/swap')
    }

    setShowMenus(false)
  }

  return (
    <div className="relative" ref={ref}>
      <div
        className="pl-[0] flex items-center cursor-pointer w-[auto] md:w-[180px]"
        onClick={() => {
          setShowMenus(prev => !prev)
        }}
      >
        <img className="w-[20px] h-[20px] rounded-full" src={NETWORK_ICON[chainId]} alt="icon" />
        <span className="font-bold text-[16px] secondary-text px-[8px] flex-1">
          {isMobile ? SHORT_NETWORK_LABELS[chainId] : NETWORK_LABELS[chainId]}
        </span>
        <StyledDropDown selected={false} />
      </div>

      {showMenus && (
        <div className="network-select">
          {NETWORKS_MAINNET?.map(item => (
            <div
              className={classNames(
                'cursor-pointer py-[8px] px-[12px] md:px-15px text-[14px] font-bold label-text2 hover:bg-[#45405B] flex items-center',
                chainId === item.chainId && 'text-white bg-[#45405B]'
              )}
              onClick={() => {
                handleChangeNetwork(item.chainId)
              }}
              key={item.chainId}
            >
              <img src={item.icon} alt="ico" className="w-[20px] mr-[8px]" />
              <p className="flex-1">{isMobile ? item.shortName : item.name}</p>
              {chainId === item.chainId && <img src="/images/tick.svg" className="w-[18px]" />}
            </div>
          ))}
          {/* <div className="flex items-center justify-between py-[10px] px-[16px] md:px-15px bg-[#1A1A2D]">
            <p className="text-[14px] text-[#D3D3D3] font-semibold leading-[22px]">Enable Testnets</p>
            <SwitchField size="sm" value={enableTestnets} onChange={setEnableTestnets} />
          </div> */}

          {enableTestnets &&
            NETWORKS_TESTNET?.map((item, i) => (
              <div
                className={classNames(
                  'cursor-pointer py-[8px] px-[12px] md:px-15px text-[14px] font-bold label-text2 hover:bg-[#45405B] flex items-center',
                  chainId === item.chainId && 'text-white bg-[#45405B]',
                  i === NETWORKS_TESTNET.length - 1 && '!border-0'
                )}
                onClick={() => {
                  handleChangeNetwork(item.chainId)
                }}
                key={item.chainId}
              >
                <img src={item.icon} alt="ico" className="w-[20px] mr-[8px]" />
                <p className="flex-1">{isMobile ? item.shortName : item.name}</p>
                {chainId === item.chainId && <img src="/images/tick.svg" className="w-[18px]" />}
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

// const SafeMoonIcon = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NetworkStatus = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  `};
`

// const SafeMoonLogo = styled.img`
//   width: 40px;
//   height: 40px;
//   margin-right: 12px;

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     margin-right: 0;
//   `}
// `

// const SafeMoonType = styled.img`
//   width: 116px;
//   height: 34px;

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     display: none;
//   `}
// `

const StyledRowBetween = styled(RowBetween)`
  padding: 10px 32px 6px 32px;
  align-items: center;
  background-color: ${({ theme }) => theme.bgMain};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 10px 20px;
  `}
`

export default function Header({ numberMessages, setQuery }: { numberMessages?: number; setQuery?: any }) {
  const { account, chainId } = useActiveWeb3React()

  const query = useParsedQueryString()
  const modals = useGetModals()
  const history = useHistory()
  const location = useLocation()

  const [oldQuery, setOldQuery] = useState(query)

  useEffect(() => {
    setQuery(query)
    if (query.viewMode !== oldQuery.viewMode) {
      history.replace(
        `${location.pathname}${convertObjectToSearchParams({
          viewMode: oldQuery.viewMode,
          showBalance: oldQuery.showBalance,
          ...query
        })}`
      )
    } else {
      setOldQuery(query)
    }
  }, [query])

  useEffect(() => {
    const element = document.getElementById('bg-video')
    if (element && modals?.length === 0) {
      element.style.zIndex = '1'
    }
  }, [modals])

  return (
    <HeaderFrame style={numberMessages > 0 && (!isMobile || isTablet) ? { top: `${75 * numberMessages}px` } : {}}>
      <StyledRowBetween style={{ alignItems: 'center' }}>
        <HeaderElement>
          <Title href={query.viewMode !== 'mobile' ? 'https://safemoon.com/' : undefined} target="_blank">
            <img
              src={isMobile ? '/logo.png' : '/images/sfm-migration.png'}
              className={classNames(isMobile ? 'w-[48px]' : 'w-[240px]')}
              alt="logo"
              style={{
                width: isMobile ? '48px' : '240px'
              }}
            />
          </Title>
          {/* {showMoonpay && query.viewMode !== 'mobile' && (
            <button
              className="items-center ml-[20px] btn-buy-sell-moonpay hidden md:flex mb-[0]"
              onClick={handleShowBuySellMoonpay}
            >
              <img src="/images/moonpaylogo.svg" className="w-[108px]" alt="moonpay-logo" />
              <span className="mx-[9px] text-[20px] font-semibold">|</span>
              <span className="text-[20px] font-semibold ">Buy / Sell</span>
            </button>
          )} */}
        </HeaderElement>
        <HeaderControls>
          <HeaderElement>
            <AccountElement
              active={!!account}
              style={{ pointerEvents: 'auto' }}
              viewMode={query?.viewMode}
              className={!account && '!mr-[10px]'}
            >
              {!isMobile && query.viewMode !== 'mobile' && (
                <div className="hidden md:block">
                  <Web3Status />
                </div>
              )}

              {/* {NETWORK_LABELS[chainId] && (
                <NetworkStatus style={{ flexShrink: 0 }} pl="12px" pr="12px" fontWeight={500}>
                  <SelectNetwork viewMode={query?.viewMode} showBalance={query?.showBalance} />
                </NetworkStatus>
              )} */}
            </AccountElement>
          </HeaderElement>
          {(isMobile || query?.viewMode === 'mobile') && (
            <>
              <div className="block">
                <Web3Status />
              </div>
            </>
          )}

          {/* <HeaderElementWrap>
            <Settings />
          </HeaderElementWrap> */}
        </HeaderControls>
      </StyledRowBetween>

      {/* {showMoonpay && query.viewMode !== 'mobile' && (
        <button
          className="items-center mt-[10px] btn-buy-sell-moonpay flex md:!hidden px-[16px] w-full justify-start"
          onClick={handleShowBuySellMoonpay}
        >
          <img src="/images/moonpaylogo.svg" className="w-[108px]" alt="moonpay-logo" />
          <span className="mx-[9px] text-[20px] font-semibold">|</span>
          <span className="text-[20px] font-semibold ">Buy / Sell</span>
        </button>
      )} */}

      <WrongNetwork />
    </HeaderFrame>
  )
}
