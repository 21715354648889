import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import io from 'socket.io-client'
// import ShowMoreText from "react-show-more-text";
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header, { Title } from '../components/Header'
import Popups from '../components/Popups'
import PageNotFound from './PageNotFound'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'

// import AddLiquidity from './AddLiquidity'
// import {
//   RedirectDuplicateTokenIds,
//   RedirectOldAddLiquidityPathStructure,
//   RedirectToAddLiquidity
// } from './AddLiquidity/redirects'
// import Pool from './Pool'
// import PoolFinder from './PoolFinder'
// import RemoveLiquidity from './RemoveLiquidity'
// import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap, RedirectToSwap2 } from './Swap/redirects'
import { ethers } from 'ethers'
import { useGasPrices, useGasType } from '../state/user/hooks'
import { useDispatch } from 'react-redux'
import { updateGasPrice, updateGasPricesList, updateVersion } from '../state/user/actions'
import axios from 'axios'
import { useActiveWeb3React } from '../hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { ChainId } from '@safemoon/sdk'
import { popupEmitter, PopupTypes, SAVE_LOG_URL, MESSAGE_ALERTS_URL, MARKETDATA_URL } from '../constants'
import { BlacklistWalletPopup } from '../components/BlacklistWalletPopup'
import { BlacklistTokenPopup } from '../components/BlacklistTokenPopup'
// import ManageLpf from './ManageLpf'
// import Bridge from './Bridge'
// import Banner from './Swap/Banner'
import { toWei } from '../utils'
import ConfirmModal from '../components/ConfirmModal'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import LPFWarningPopup from './ManageLpf/LPFWarningPopup'
// import { SwapPoolTabs } from '../components/NavigationTabs'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div<{ viewMode?: any }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${({ viewMode }: any) => (viewMode === 'mobile' ? '100px' : '160px')};
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: ${({ viewMode }: any) => (viewMode === 'mobile' ? 0 : '16px')};
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  const gasPrices = useGasPrices()
  const gasType = useGasType()
  const dispatch = useDispatch()
  const { chainId, account } = useActiveWeb3React()
  const [showBlacklistWallet, setShowBlacklistWallet] = useState(false)
  const [showBlacklistToken, setShowBlacklistToken] = useState(false)
  const [messageAlerts, setMessageAlerts] = useState([])
  const [showConfirm, setShowConfirm] = useState<any>()
  const [query, setQuery] = useState<any>({})

  useEffect(() => {
    if (showConfirm) {
      handleConfirm(true, showConfirm)
    }
  }, [query])

  useEffect(() => {
    const getMessageAlerts = async () => {
      try {
        const result = await axios.get(MESSAGE_ALERTS_URL, {
          params: {}
        })

        const ids = sessionStorage.getItem('DELETE_MESSAGE_IDS')
        const messageIds = ids ? ids.split(',') : []

        const listMessageAlert = []

        result.data.docs?.filter(message => {
          if (
            messageIds?.indexOf(message._id) === -1 &&
            (message.alertType === 'SWAP' || message.alertType === 'MAINTENANCE')
          ) {
            listMessageAlert.push(message)
          }
          if (message.alertType === 'MAINTENANCE') {
            setShowConfirm({ ...message, hideClose: true })
          }
        })

        setMessageAlerts(listMessageAlert)
      } catch (e) {
        console.log(e)
      }
    }

    // getMessageAlerts()
  }, [])

  const viewMode = useMemo(() => {
    return window.location.hash?.indexOf('viewMode=mobile') !== -1 ? 'mobile' : ''
  }, [])

  useEffect(() => {
    if (account) {
      axios.post(SAVE_LOG_URL, {
        address: account,
        source: viewMode === 'mobile' ? 'WEB_SWAP_MOBILE' : 'WEB_SWAP'
      })
    }
  }, [account, viewMode])

  useEffect(() => {
    const socketIo = io(process.env.REACT_APP_SOCKET_URL, {
      query: {
        type: 'User',
        connectionType: 'PUBLIC'
      },
      path: '/chat-socket'
    })

    socketIo.on(`gas-price-${chainId}`, data => {
      console.log('gas-price data', data)
      dispatch(updateGasPricesList({ gasPrices: data, chainId }))
    })

    return () => {
      if (socketIo) {
        socketIo.disconnect()
      }
    }
  }, [chainId])

  const getBscGasPrice = useCallback(async () => {
    try {
      const bscGasPrice = {
        default: BigNumber.from(3)
          .mul(10 ** 9)
          .toString(),
        fast: BigNumber.from(4)
          .mul(10 ** 9)
          .toString(),
        instant: BigNumber.from(5)
          .mul(10 ** 9)
          .toString(),
        testnet: BigNumber.from(10)
          .mul(10 ** 9)
          .toString()
      }

      dispatch(updateGasPricesList({ gasPrices: bscGasPrice, chainId: ChainId.BSC_MAINNET }))

      const amoyGasPrice = {
        default: BigNumber.from(30)
          .mul(10 ** 9)
          .toString(),
        fast: BigNumber.from(35)
          .mul(10 ** 9)
          .toString(),
        instant: BigNumber.from(35)
          .mul(10 ** 9)
          .toString(),
        testnet: BigNumber.from(35)
          .mul(10 ** 9)
          .toString()
      }

      const avalancheGasPrice = {
        default: BigNumber.from(25)
          .mul(10 ** 9)
          .toString(),
        fast: BigNumber.from(27)
          .mul(10 ** 9)
          .toString(),
        instant: BigNumber.from(36)
          .mul(10 ** 9)
          .toString(),
        testnet: BigNumber.from(36)
          .mul(10 ** 9)
          .toString()
      }

      const baseSepoliaGasPrice = {
        default: BigNumber.from(35)
          .mul(10 ** 9)
          .toString(),
        fast: BigNumber.from(35)
          .mul(10 ** 9)
          .toString(),
        instant: BigNumber.from(39)
          .mul(10 ** 9)
          .toString(),
        testnet: BigNumber.from(39)
          .mul(10 ** 9)
          .toString()
      }

      const arbSepoliaGasPrice = {
        default: BigNumber.from(10)
          .mul(10 ** 9)
          .toString(),
        fast: BigNumber.from(10)
          .mul(10 ** 9)
          .toString(),
        instant: BigNumber.from(10)
          .mul(10 ** 9)
          .toString(),
        testnet: BigNumber.from(10)
          .mul(10 ** 9)
          .toString()
      }

      dispatch(updateGasPricesList({ gasPrices: bscGasPrice, chainId: ChainId.BSC_TESTNET }))
      dispatch(updateGasPricesList({ gasPrices: amoyGasPrice, chainId: ChainId.MUMBAI_TESTNET }))
      dispatch(updateGasPricesList({ gasPrices: avalancheGasPrice, chainId: ChainId.AVALANCHE_FUJI }))
      dispatch(updateGasPricesList({ gasPrices: baseSepoliaGasPrice, chainId: ChainId.BASE_SEPOLIA }))
      dispatch(updateGasPricesList({ gasPrices: arbSepoliaGasPrice, chainId: ChainId.ARB_TESTNET }))
    } catch (e) {
      console.log(e)
    }
  }, [dispatch])

  useEffect(() => {
    getBscGasPrice()
  }, [getBscGasPrice])

  useEffect(() => {
    const showBlacklistWalletPopup = () => {
      setShowBlacklistWallet(true)
    }
    const showBlacklistTokenPopup = () => {
      setShowBlacklistToken(true)
    }
    popupEmitter.on(PopupTypes.BLACKLIST_WALLET, showBlacklistWalletPopup)
    popupEmitter.on(PopupTypes.BLACKLIST_TOKEN, showBlacklistTokenPopup)

    return () => {
      popupEmitter.off(PopupTypes.BLACKLIST_WALLET, showBlacklistWalletPopup)
      popupEmitter.off(PopupTypes.BLACKLIST_TOKEN, showBlacklistTokenPopup)
    }
  }, [setShowBlacklistWallet, setShowBlacklistToken])

  useEffect(() => {
    dispatch(updateVersion())
  }, [dispatch])

  useEffect(() => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
      provider.on('network', (newNetwork, oldNetwork) => {
        if (oldNetwork && window?.location?.hash.indexOf('bridge') === -1) {
          // window.location.reload()
        }
      })
    }
  }, [])

  useEffect(() => {
    dispatch(updateGasPrice({ gasPrice: gasPrices[gasType], gasPriceType: gasType }))
  }, [gasPrices, gasType, chainId, dispatch])

  const handleConfirm = (dontShowAgain, message) => {
    setMessageAlerts(prev => prev.filter(item => item._id !== message._id))
    if (dontShowAgain) {
      const ids = sessionStorage.getItem('DELETE_MESSAGE_IDS')
      const messageIds = ids ? ids.split(',') : []
      sessionStorage.setItem('DELETE_MESSAGE_IDS', [...messageIds, message._id].join(','))
    }
    setShowConfirm(null)
  }

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <AppWrapper>
          <div id="messageAlerts" className="z-[99] relative w-full">
            {messageAlerts && messageAlerts?.length > 0 && (
              <div className="flex flex-col items-center w-full">
                {messageAlerts?.map((message, i) => (
                  <div
                    className="alert-wraper flex items-center lg:justify-center"
                    key={message._id}
                    style={i === messageAlerts?.length - 1 ? { marginBottom: 0 } : {}}
                  >
                    <div
                      className="alert-text lg:text-center"
                      key={message._id}
                      dangerouslySetInnerHTML={{ __html: message.shortMessage }}
                    />
                    {message.buttonText && (
                      <a
                        className="cursor-pointer btnLearnMore"
                        onClick={e => {
                          if (message.actionType === 'OPEN_POPUP') {
                            e.preventDefault()
                            e.stopPropagation()
                            setShowConfirm(message)
                          }
                        }}
                        href={message.url}
                        target={message.actionType === 'OPEN_LINK_NEW_TAB' ? '_blank' : '_self'}
                        rel="noreferrer"
                      >
                        {message.buttonText || 'Learn More'}
                      </a>
                    )}

                    {message.alertType !== 'MAINTENANCE' && (
                      <a
                        className="cursor-pointer btnClose"
                        onClick={() => {
                          handleConfirm(true, message)
                        }}
                      >
                        <img src={'/images/close-white.svg'} className="w-[20px]" />
                      </a>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {!(showConfirm && showConfirm.alertType === 'MAINTENANCE') ? (
            <HeaderWrapper>
              <Header
                numberMessages={messageAlerts && messageAlerts?.length > 0 ? messageAlerts?.length : 0}
                setQuery={setQuery}
              />
            </HeaderWrapper>
          ) : (
            <Title
              href={query?.viewMode !== 'mobile' ? 'https://safemoon.com/' : undefined}
              target="_blank"
              className="z-[3] px-[20px] lg:px-[80px] pt-[10px] lg:pt-[16px]"
            >
              <img
                src={isMobile ? '/logo.png' : '/images/swap-logo.svg'}
                className={classNames(isMobile ? 'w-[48px]' : 'w-[240px]')}
                alt="logo"
                style={{
                  width: isMobile ? '48px' : '240px'
                }}
              />
            </Title>
          )}

          <Popups />
          <BodyWrapper viewMode={viewMode}>
            <BlacklistWalletPopup open={showBlacklistWallet} onDismiss={() => setShowBlacklistWallet(false)} />
            <BlacklistTokenPopup open={showBlacklistToken} onDismiss={() => setShowBlacklistToken(false)} />
            <ConfirmModal
              show={!!showConfirm}
              handleClose={() => {
                handleConfirm(true, showConfirm)
                setShowConfirm(null)
              }}
              handleConfirm={handleConfirm}
              message={showConfirm}
            />
            {/* <SwapPoolTabs /> */}
            {viewMode !== 'mobile' && <div style={{ height: '66px' }} />}
            <Web3ReactManager>
              {!(showConfirm && showConfirm.alertType === 'MAINTENANCE') && (
                <Switch>
                  <Route exact strict path="/" render={() => <Redirect to="/swap" />} />
                  <Route exact strict path="/swap" component={Swap} />
                  <Route exact strict path="/swap/:currencyIdA/:currencyIdB" component={RedirectToSwap2} />
                  <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                  <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                  {/* <Route exact strict path="/bridge" component={Bridge} /> */}
                  {/* <Route exact strict path="/lpf/find" component={PoolFinder} /> */}
                  {/* <Route exact strict path="/lpf/pool" component={Pool} /> */}
                  {/* <Route exact strict path="/lpf/reward" component={ManageLpf} /> */}

                  {/* <Route exact strict path="/lpf/create" component={RedirectToAddLiquidity} /> */}
                  {/* <Route exact path="/lpf/add" component={AddLiquidity} /> */}
                  {/* <Route exact path="/lpf/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} /> */}
                  {/* <Route exact path="/lpf/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} /> */}
                  {/* <Route exact strict path="/lpf/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} /> */}
                  {/* <Route exact strict path="/lpf/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} /> */}
                  <Route component={PageNotFound} />
                </Switch>
              )}
            </Web3ReactManager>
            <Marginer />
            {/* <LPFWarningPopup setShowConfirm={setShowConfirm} /> */}
          </BodyWrapper>
          <ToastContainer />
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
